import { FC, isValidElement } from 'react'
import toast from 'react-hot-toast'

import cn from 'classnames'

import { copyTextToClipboard, isValidArray, isValidString, tr } from 'mmfintech-commons'

import ClipboardIcon from '@images/icons/clipboard.svg?react'

import './corePreviewLine.scss'

type iconType =
  | 'account'
  | 'date'
  | 'fee'
  | 'method'
  | 'recipient'
  | 'reference'
  | 'transaction-id'
  | 'category-transaction'
  | 'percentage'
  | 'description'

interface ICorePreviewLineProps {
  iconType?: iconType
  title: string
  text: string | string[] | JSX.Element
  className?: string
  value?: string
  isCopyVisible?: boolean
}

const CorePreviewLine: FC<ICorePreviewLineProps> = ({ iconType, text, title, className, value, isCopyVisible }) => {
  if (iconType === 'method' && isValidString(text)) {
    text = text.toLowerCase().replace(/_/g, ' ')
  }

  return text ? (
    <div className={cn('preview-line-wrapper', className)}>
      <div>
        <span className='preview-line-label' data-test={`preview-line-title-${title}`}>
          {title}
        </span>
        <div
          data-test={`preview-line-text-${title}`}
          data-tip='Copied'
          data-event='click'
          data-event-off='mouseleave'
          data-for={isValidString(text) && 'preview-line-copy'}
          className={cn('preview-line-content-moving', { 'preview-line-content': iconType === 'method' })}
          onMouseOver={event => {
            const target = event.target as HTMLDivElement
            event.stopPropagation()
            if (target.clientWidth > target.closest('.preview-line-wrapper').clientWidth) {
              target.style.transform = `translateX(${-(
                target.clientWidth - target.closest('.preview-line-wrapper').clientWidth
              )}px)`
              target.style.cursor = 'pointer'
            }
          }}
          onMouseLeave={event => {
            const target = event.target as HTMLDivElement
            target.style.transform = `translateX(0px)`
          }}
          onClick={() => isValidString(text) && copyTextToClipboard(text)}>
          {isValidElement(text) && text}
          {isValidString(text) ? (
            <p>{text}</p>
          ) : isValidArray(text) ? (
            text.map((line: string, index: number) => {
              if (index == 0 && line && line.length > 10) {
                return (
                  <div key={index} className='preview-line-card-number'>
                    <p>{'*'.repeat(line.length - 4) + line.slice(-4)}</p>
                  </div>
                )
              }
              return (
                <p key={index} className='preview-line-card-details'>
                  {line}
                </p>
              )
            })
          ) : (
            ''
          )}
          {isCopyVisible && value && (
            <div
              className='copy'
              data-tip='Copied'
              data-event='click'
              data-event-off='mouseleave'
              data-for='account-details-copy'>
              <ClipboardIcon
                onClick={() =>
                  copyTextToClipboard(
                    value,
                    toast.success(tr('FRONTEND.BANKING.COMMON.COPY_SUCCESS', 'Value copied to clipboard'), {
                      position: 'top-right'
                    })
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null
}

export default CorePreviewLine
