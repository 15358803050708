import { useHistory } from 'react-router-dom'

import { paths } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import { CoreButton } from '@components'

import WelcomeIcon2 from '@images/icons/welcome_2.svg?react'
import CrossIcon from '@images/icons/cross-icon.svg?react'

import './welcomeContent2.scss'

type TWelcomeContent2Props = {}

export const WelcomeContent2 = ({}: TWelcomeContent2Props) => {
  const history = useHistory()

  return (
    <>
      <div className='welcome-step-2'>
        <div className='logo'>
          <WelcomeIcon2 />
        </div>
        <div className='title'>
          {tr('FRONTEND.WELCOME.MODAL.STEP_2.TITLE', 'You can now deposit, exchange and send your CRYPTO on the go!')}
        </div>
        <CoreButton
          variation='elevated'
          text={tr('FRONTEND.WELCOME.MODAL.STEP_2.DEPOSIT_CRYPTO', 'Deposit Crypto')}
          onClick={() => history.push(paths.banking.deposit())}
          size='large'
          LeftIcon={<CrossIcon />}
        />
        <p className='description'>
          {tr(
            'FRONTEND.WELCOME.MODAL.STEP_2.DESCRIPTION',
            'Or Complete your account to unlock FIAT Services, IBANS and many additional features'
          )}
        </p>
      </div>
    </>
  )
}
