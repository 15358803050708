import { CoreButton, CorePaymentInput } from "@components"
import CorePreviewLine from "../../../components/CorePreviewLine"
import { useRefundInitiateMutation, useRefundPreviewMutation } from "mmfintech-backend-api"
import { checkAmountLimits, fixFloat, GlobalContext, OtpContext, tr, translateError, useFormValues } from "mmfintech-commons"
import { PaymentOption, TransactionViewPortal } from "mmfintech-commons-types"
import { useContext, useState } from "react"
import toast from "react-hot-toast"
import { v4 as uuid } from 'uuid'
import { ErrorDisplay } from "mmfintech-portal-commons"

import './styled/refundModal.scss'
import moment from "moment"
import { RefundPreview } from "./RefundPreview"


export const RefundModal = ({ transaction }: { transaction: TransactionViewPortal }) => {

    const { setOtpOnSuccess } = useContext(OtpContext)
    const { modalHide } = useContext(GlobalContext)


    const { amount, id, checkoutDetails, currency, paymentMethod, reqTime } = transaction || {}
    const { customerName, customerEmail } = checkoutDetails || {}

    const paymentOption: PaymentOption = {
        status: 'AVAILABLE',
        minAmount: 0,
        maxAmount: amount
    }

    const [refundInit, { error: refundInitError, isLoading: refundInitFetching }] = useRefundInitiateMutation({
        fixedCacheKey: 'refund-init'
    })
    const [refundPreview, { error: refundPreviewError, isLoading: refundPreviewFetching, data: refundPreviewData }] =
        useRefundPreviewMutation({
            fixedCacheKey: 'refund-preview'
        })

    const isFormDataValid = () => {
        const test = checkAmountLimits(fixFloat(formValues.getValue('amount')), paymentOption)
        if (!test.valid) {
            formValues.setError('amount', translateError(test), test)
            return false
        }

        return true
    }
    const [step, setStep] = useState(1)

    const formValues = useFormValues({
        amount: { required: true, validation: 'float', value: transaction?.amount.toString() }
    })

    const handlePreview = async (payload: any) => {
        try {
            await refundPreview(payload).unwrap()
            setStep(2)
        } catch (_err) { }
    }

    const handleRefundInit = async (payload: any) => {
        setOtpOnSuccess(() => () => {
            toast.success(tr('FRONTEND.TRANSACTIONS.REFUND_SUCCESS', 'Refund initiated'))
        })
        try {
            await refundInit(payload).unwrap()
        } catch (_err) { }
    }

    const handleSubmit = () => {
        if (isFormDataValid()) {
            const payload = {
                refundForeignTransactionId: uuid(),
                originalTransactionId: transaction.id,
                amount: formValues.getValue('amount')
            }

            switch (step) {
                case 1:
                    void handlePreview(payload)
                    break

                case 2:
                    void handleRefundInit(payload)
                    break

                default:
                    break
            }
        }
    }
    return (
        <div className="refund-modal-wrapper">
            {step === 1 ? (
                <>
                    <div className='info-container'>
                        <CorePreviewLine
                            className={'transaction-refund-single-content'}
                            title={tr('FRONTEND.TRANSACTIONS.REFUND_MODAL.TRANSACTION_ID', 'Transaction ID')}
                            text={id?.toString()}
                        />
                        <CorePreviewLine
                            className={'transaction-refund-single-content'}
                            title={tr('FRONTEND.TRANSACTIONS.REFUND_MODAL.DATE', 'Date')}
                            text={moment(reqTime).format('DD/MM/YYYY')}
                        />
                        <CorePreviewLine
                            className={'transaction-refund-single-content'}
                            title={tr('FRONTEND.TRANSACTIONS.REFUND_MODAL.PAYMENT_METHOD', 'Payment method')}
                            text={tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}
                        />
                        <CorePreviewLine
                            className={'transaction-refund-single-content'}
                            title={tr('FRONTEND.TRANSACTIONS.REFUND_MODAL.CUSTOMER_NAME', 'Customer name')}
                            text={customerName}
                        />
                        <CorePreviewLine
                            className={'transaction-refund-single-content'}
                            title={tr('FRONTEND.TRANSACTIONS.REFUND_MODAL.CUSTOMER_EMAIL', 'Customer email')}
                            text={customerEmail}
                        />
                    </div>

                    <div className='amount-container'>
                        <CorePaymentInput
                            type="label"
                            currencyCodeOverride={currency}
                            error={formValues.getError('amount')}
                            value={formValues.getValue('amount')}
                            onChange={value => formValues.setValue('amount', value)}
                            paymentOption={paymentOption}
                            dataTest="refund-amount"
                            label={tr('FRONTEND.TRANSACTIONS.REFUND_MODAL.AMOUNT', 'Amount')}
                        />
                    </div>
                </>
            ) : null}

            {step === 2 ? <RefundPreview previewData={refundPreviewData} checkoutDetails={checkoutDetails} trn={transaction} /> : null}

            <ErrorDisplay error={[refundInitError, refundPreviewError]} />

            <div className="refund-bottom-actions">

                <CoreButton
                    size="large"
                    variation="primary"
                    fullWidth
                    onClick={handleSubmit}
                    text={step === 1
                        ? tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')
                        : tr('FRONTEND.TRANSACTIONS.REFUND_MODAL.REFUND_BUTTON', 'Refund')
                    }
                    disabled={formValues.getFloat('amount') <= 0}
                    isLoading={refundInitFetching || refundPreviewFetching}
                />

                <CoreButton
                    size="large"
                    variation="tertiary"
                    onClick={modalHide}
                    text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
                />
            </div>
        </div>
    )
}
