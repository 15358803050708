import { useContext, useEffect } from 'react'
import cn from 'classnames'

import { formatPhoneNumber, isValidArray, OtpContext, tr, useValidUntilTimer } from 'mmfintech-commons'
import { ChallengeDetails, LoginStatusEnum, ChallengePurposeEnum, TwoFactorTypeEnum } from 'mmfintech-commons-types'
import { globalSettings, useOtpQry } from 'mmfintech-backend-api'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { CoreLoader, GoogleAuthenticator } from '@components'
import { CoreButton } from '../CoreButton'
import { OtpInput } from './OtpInput'

import SmartIdConfirmationImage from '@images/smartId-confirmation.png'
import OtpConfirmationImage from '@images/otp-confirmation.png'
import SmartIdIcon from '@images/icons/smart-id-icon.svg?react'
import EmailIcon from '@images/icons/opt-email.svg?react'
import SMSIcon from '@images/icons/sms-icon.svg?react'
import GaIcon from '@images/icons/opt-ga.svg?react'

import './otp.scss'

const CHANGE_2FA_OPTIONS = [
  ChallengePurposeEnum.CHANGE_2FA,
  ChallengePurposeEnum.CHANGE_2FA_TOTP,
  ChallengePurposeEnum.CHANGE_2FA_PHONE
]

export const prepareOtpTitle = (challenge: ChallengeDetails, userStatus: string) => {
  const { twoFactorType } = challenge || {}

  if (twoFactorType === TwoFactorTypeEnum.SMART_ID) {
    return tr('FRONTEND.VERIFY_OTP.SMART_ID.TITLE', "Verify it's you")
  }

  if (twoFactorType === TwoFactorTypeEnum.SMS) {
    if (userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_SMS', 'SMS verification')
    }
    return tr('FRONTEND.VERIFY_OTP.TITLE_SMS', 'SMS confirmation')
  }

  if (twoFactorType === TwoFactorTypeEnum.TOTP) {
    if (userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_TOTP', 'Google Authenticator verification')
    }
    return tr('FRONTEND.VERIFY_OTP.TITLE_TOTP', 'Google Authenticator confirmation')
  }

  if (userStatus === LoginStatusEnum.SECOND_FACTOR || userStatus === LoginStatusEnum.LOGGED_IN) {
    return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_EMAIL', 'Email verification')
  }
  return tr('FRONTEND.VERIFY_OTP.TITLE_EMAIL', 'Email confirmation')
}

export const OtpNew = () => {
  const {
    code,
    codeSize,
    attempts,
    challenge,
    availableTwoFactorTypes,
    handleSubmit,
    handleResend,
    handleCodeChanged,
    handleChangeOtpType,
    verifyOtpError,
    verifyOtpFetching,
    resendOtpError,
    resendTimer,
    handleCancel
  } = useOtpQry({ autoSubmit: true })

  const timer = useValidUntilTimer()
  const { setOtpOnError, setOtpOnSuccess } = useContext(OtpContext)

  const { sentTo, twoFactorType, totpSecretKeyUri, challengePurpose } = challenge || {}

  useEffect(() => {
    return () => {
      setOtpOnSuccess(null)
      setOtpOnError(null)
    }
  }, [totpSecretKeyUri])

  const alternativeOtpMethods = (method: string) => {
    switch (method) {
      case TwoFactorTypeEnum.SMS:
        return (
          <div className='alternative-method-wrapper'>
            <SMSIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.SMS.LABEL', 'SMS')}</span>
          </div>
        )
      case TwoFactorTypeEnum.SMART_ID:
        return (
          <div className='alternative-method-wrapper'>
            <SmartIdIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.SMART_ID.LABEL', 'Smart ID')}</span>
          </div>
        )
      case TwoFactorTypeEnum.EMAIL:
        return (
          <div className='alternative-method-wrapper'>
            <EmailIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.EMAIL.LABEL', 'Email')}</span>
          </div>
        )
      case TwoFactorTypeEnum.TOTP:
        return (
          <div className='alternative-method-wrapper'>
            <GaIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.GA.LABEL', 'GA code')}</span>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className='otp-modal-wrapper'>
      <form
        noValidate
        onSubmit={() => {
          if (!verifyOtpFetching) {
            void handleSubmit()
          }
        }}>
        {totpSecretKeyUri ? (
          <GoogleAuthenticator totpSecretKeyUri={totpSecretKeyUri} />
        ) : (
          <>
            <img
              src={twoFactorType === TwoFactorTypeEnum.SMART_ID ? SmartIdConfirmationImage : OtpConfirmationImage}
              alt='confirmation-icon'
            />
            <div className='otp-modal-subtitle'>
              {twoFactorType === TwoFactorTypeEnum.SMART_ID ? (
                tr(
                  'FRONTEND.VERIFY_OTP.SMART_ID.TEXT',
                  'To ensure a secure login, approve the sign-in request sent to your mobile app.'
                )
              ) : (
                <div className='otp-verification-text'>
                  {twoFactorType === TwoFactorTypeEnum.TOTP
                    ? tr(
                        'FRONTEND.VERIFY_OTP.TOTP_TARGET_LABEL',
                        'To ensure a secure login, enter the verification code from your Google Authenticator app.'
                      )
                    : twoFactorType === TwoFactorTypeEnum.EMAIL
                    ? tr(
                        'FRONTEND.VERIFY_OTP.EMAIL_SUBTITLE',
                        'To ensure a secure login, we’ve sent a code to your email. Enter it below to proceed.'
                      )
                    : tr(
                        'FRONTEND.VERIFY_OTP.SMS_SUBTITLE',
                        'To ensure a secure login, we’ve sent a code via SMS. Enter it below to proceed.'
                      )}
                  <div className='otp-verification-target'>
                    {twoFactorType === 'SMS' ? formatPhoneNumber(sentTo) : sentTo}
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <>
          <div className='countdown'>{timer.formattedTime}</div>
          <OtpInput
            codeLength={codeSize}
            onChange={handleCodeChanged}
            disabled={timer.expired || attempts >= globalSettings.otpMaxAttempts}
            value={code}
          />
        </>

        <div className='otp-error-display-wrapper'>
          <ErrorDisplay error={[verifyOtpError, resendOtpError]} />
        </div>

        {isValidArray(availableTwoFactorTypes?.filter(type => type !== twoFactorType)) &&
          !CHANGE_2FA_OPTIONS.includes(challengePurpose as ChallengePurposeEnum) && (
            <>
              <div className='otp-modal-methods-title'>
                {tr('FRONTEND.VERIFY_OTP.NOT_RECEIVED', "Haven't received it?")}
              </div>
              {challengePurpose !== ChallengePurposeEnum.CHANGE_2FA_TOTP &&
                twoFactorType !== TwoFactorTypeEnum.TOTP && (
                  <>
                    {attempts < globalSettings.otpMaxAttempts ? (
                      <span
                        onClick={handleResend}
                        className={cn('otp-resend-button', { disabled: !resendTimer.expired })}>
                        {resendTimer.expired
                          ? tr('FRONTEND.VERIFY_OTP.RESEND_CODE', 'Re-send code')
                          : resendTimer.remainingTime}
                      </span>
                    ) : (
                      tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
                    )}
                  </>
                )}
              <div className='otp-modal-methods-title'>
                {tr('FRONTEND.VERIFY_OTP.ANOTHER_METHOD', 'Try another method:')}
              </div>
              <div className={cn('otp-modal-methods-actions', { disable: !resendTimer.expired })}>
                {attempts < globalSettings.otpMaxAttempts ? (
                  <>
                    {availableTwoFactorTypes
                      .filter(type => type !== twoFactorType)
                      .map(type => {
                        return (
                          <div
                            className='otp-alternative-method'
                            key={type}
                            onClick={() => {
                              resendTimer.expired && handleChangeOtpType({ type })
                            }}>
                            {alternativeOtpMethods(type)}
                          </div>
                        )
                      })}
                  </>
                ) : (
                  tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
                )}
              </div>
            </>
          )}

        {/* {!smartId && (
          <CoreButton
            text={tr('FRONTEND.BUTTONS.SUBMIT', 'Submit')}
            fullWidth={true}
            variation='primary'
            disabled={code.length < codeSize || errorKey === TEMPORARY_UNAVAILABLE_OTP}
            isLoading={verifyOtpFetching || resendOtpFetching}
            size='large'
            type='button'
            onClick={() => {
              if (!verifyOtpFetching) {
                void handleSubmit()
              }
            }}
          />
        )} */}

        <div style={{ width: '100%' }} className='mt-4'>
          {verifyOtpFetching ? (
            <CoreLoader />
          ) : (
            <CoreButton
              fullWidth
              text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
              onClick={() => handleCancel()}
              size='large'
              isLoading={verifyOtpFetching}
            />
          )}
        </div>
      </form>
    </div>
  )
}
