import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { resetEmailVerification } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'

import { CoreButton, CoreModalDialogFooter } from '@components'
import { WelcomeContent2 } from './WelcomeContent2'
import { useOnboarding } from '@hooks'

import './welcomeModal.scss'

type TWelcomeModalProps = {}

export const WelcomeModal = ({}: TWelcomeModalProps) => {
  const { startOrContinueOnboarding } = useOnboarding()
  const { modalHide } = useContext(GlobalContext)

  const dispatch = useDispatch()

  const onResetEmailVerification = () => {
    dispatch(resetEmailVerification())
  }

  useEffect(() => {
    return () => {
      onResetEmailVerification()
    }
  }, [])

  return (
    <div className='welcome-wrapper'>
      <div className='welcome-container' data-test='welcome-modal'>
        <WelcomeContent2 />

        <CoreModalDialogFooter>
          <div className='footer-buttons'>
            <CoreButton
              text={tr('FRONTEND.WELCOME.MODAL.START_ONBOARDING', 'Start Onboarding')}
              onClick={() => {
                startOrContinueOnboarding()
              }}
              size='large'
              fullWidth
            />
            <CoreButton
              variation='tertiary'
              text={tr('FRONTEND.WELCOME.MODAL.DISMISS', 'Dismiss')}
              onClick={() => {
                modalHide()
              }}
              size='large'
            />
          </div>
        </CoreModalDialogFooter>
      </div>
    </div>
  )
}
