import { useEffect, useState } from 'react'

import { DragAndDrop, FileInput, ErrorDisplay } from 'mmfintech-portal-commons'

import { tr, useFormValues, checkFileUpload, isValidFileArrayOrFileList, isValidArray } from 'mmfintech-commons'
import { TransactionDocumentType } from 'mmfintech-commons-types'

import {
  fileUploadTypes,
  useLazyFetchDocumentTypesQuery,
  useTransactionFilesManagementQry
} from 'mmfintech-backend-api'
import settings from '@settings'

import { CoreButton, CoreInput, CoreModalDialogFooter, CoreSelect, FileUploadedPane, FileUploadView } from '@components'

import './fileUploadModal.scss'

export const FileUploadModal = ({ onHide }: { onHide: () => void }) => {
  const { uploadFile, uploadFileFetching, uploadFileError } = useTransactionFilesManagementQry({
    onSuccess: () => {
      onHide()
    }
  })
  const [fetchDocumentTypes, { data: withdrawUploadTypes, error: withdrawUploadTypesError }] =
    useLazyFetchDocumentTypesQuery()

  const [file, setFile] = useState<File>()
  const { areValid, getValue, registerInput } = useFormValues({
    documentTypeInput: { validation: 'safe-string' }
  })
  const [fileType, setFileType] = useState<TransactionDocumentType>()

  const [uploadTypesOptions, setUploadTypesOptions] = useState([])
  const [fileError, setFileError] = useState(null)

  const handleError = (errors: any) => setFileError(checkFileUpload(errors))

  const handleRemoveFile = () => {
    setFile(undefined)
  }
  const handleSelected = (files: FileList | File[]) => {
    setFileError(null)
    if (isValidFileArrayOrFileList(files)) {
      setFile(files[0])
    }
  }

  const handleUploadDocument = async () => {
    if (areValid()) {
      try {
        await uploadFile({
          type: fileType,
          file,
          documentTypeInput: getValue('documentTypeInput')
        })
      } catch (e) {}
    }
  }

  useEffect(() => {
    if (!isValidArray(withdrawUploadTypes)) {
      fetchDocumentTypes(null)
    } else {
      setUploadTypesOptions(
        withdrawUploadTypes.map((option: TransactionDocumentType) => {
          return { value: option, label: fileUploadTypes.getName(option) }
        })
      )
    }
    //eslint-disable-next-line
  }, [withdrawUploadTypes])

  return (
    <>
      <div className='file-upload-wrapper' data-test='bank-transfer-file-upload-modal'>
        <div className='file-upload-title'>{tr('FRONTEND.FILE_UPLOAD.SELECT_FILE', 'Upload file')}</div>
        <DragAndDrop
          onDrop={handleSelected}
          onError={handleError}
          acceptType={settings.questionnaireFileTypes}
          maxFileSize={settings.questionnaireMaxFileSize}>
          <FileInput
            maxNumber={1}
            acceptType={settings.questionnaireFileTypes}
            maxFileSize={settings.questionnaireMaxFileSize}
            onChange={handleSelected}
            onError={handleError}>
            {({ onFileUpload, onRemoveAll }: any) =>
              file ? (
                <FileUploadedPane file={file} onRemove={() => handleRemoveFile()} />
              ) : (
                <FileUploadView
                  onClick={() => {
                    onRemoveAll()
                    onFileUpload()
                  }}
                  types={settings.questionnaireFileTypes}
                  message='Drag & Drop to Upload'
                />
              )
            }
          </FileInput>
        </DragAndDrop>
        <span className='additional-information'>Additional information</span>
        <CoreSelect
          className='type'
          type='default'
          name='attachmentType'
          required
          data-test='destination-payment-instrument-id'
          label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.SUPPLEMENTAL_DOCUMENTS.UPLOAD.TYPE', 'Attachment Type')}
          value={fileType}
          options={uploadTypesOptions}
          onChange={(_name: string, value: string) => setFileType(value as TransactionDocumentType)}
        />
        {fileType === 'OTHER' && (
          <CoreInput
            required
            name='other'
            type='text'
            data-test='specify-document-input'
            label={tr(
              'FRONTEND.WITHDRAW.BANK_TRANSFER.SUPPLEMENTAL_DOCUMENTS.UPLOAD.SPECIFY',
              'Describe the attached document'
            )}
            {...registerInput('documentTypeInput')}
          />
        )}
        <ErrorDisplay error={[withdrawUploadTypesError, fileError, uploadFileError]} />
      </div>
      <CoreModalDialogFooter className='upload-footer'>
        <CoreButton
          className='upload-action'
          size='large'
          text={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.SUPPLEMENTAL_DOCUMENTS.UPLOAD.MODAL.BUTTON', 'Upload')}
          onClick={handleUploadDocument}
          disabled={!file || !fileType || (fileType === 'OTHER' && !getValue('documentTypeInput'))}
          isLoading={uploadFileFetching}
          fullWidth
        />
      </CoreModalDialogFooter>
    </>
  )
}
