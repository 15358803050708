import { BankOut, CountrySupportedPurposeEnum, InstrumentFieldEnum, RecipientOut } from 'mmfintech-commons-types'
import '../styles/bankRecipientEdit.scss'
import { recipientKeyToCode, splitBankRecipientData, useEditBankAccountQry } from '@hooks'
import {
  getExternalBankAccountFieldLabel,
  useCountries,
  useEligibleAccountsForRecipientQuery
} from 'mmfintech-backend-api'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { CoreButton, CoreInput, CoreLoader, CoreSelect, CoreSteps } from '@components'
import { isValidArray, isValidObject, tr } from 'mmfintech-commons'
import { useMemo, useState } from 'react'

const obsoleteFields = ['foreignId', 'currency', 'ownAccount']

export const BankRecipientEdit = ({ recipient, onSuccess, independentEdit }:
  { recipient?: RecipientOut; onSuccess?: () => void; independentEdit?: boolean }
) => {
  const recipientId = recipient?.paymentInstrumentId || 0

  const [step, setStep] = useState(1)

  const { data: eligibleAccounts, isLoading } = useEligibleAccountsForRecipientQuery(recipientId)
  const { countryOptions, countryOptionsFiltered } = useCountries()

  const filteredCountries = useMemo(() => {
    const account = isValidArray(eligibleAccounts) ? eligibleAccounts[0] : null
    return countryOptionsFiltered(CountrySupportedPurposeEnum.WITHDRAWAL, account?.supportedCountries)
  }, [countryOptions, eligibleAccounts])

  const {
    formValues,
    countryCode,
    accountTypeOptions,
    documentTypesOptions,
    handleSubmit,
    providedBanks,
    saveRecipientError,
    saveRecipientLoading,
    bankMetadataFetching,
    documentTypesFetching
  } = useEditBankAccountQry({
    accountId: isValidArray(eligibleAccounts) ? eligibleAccounts[0].id : null,
    recipientId,
    currencyCode: isValidArray(eligibleAccounts) ? eligibleAccounts[0].currencyCode : null,
    onSuccess,
    disableBankMetadata: true,
    enableProvidedBanks: true,
    independentEdit
  })

  const providedBanksOptions = () =>
    isValidArray(providedBanks)
      ? providedBanks
        .filter((v: BankOut) => !!v.code?.length)
        .map((bank: BankOut) => ({ value: bank.id, label: bank.name }))
      : []

  const handleBankChange = (bankId: string): void => {
    const found = providedBanks?.find((bank: BankOut) => bank.id === Number(bankId))
    if (found) {
      formValues.setValue('bankName', found.name)
      formValues.setValue('bankCode', found.code)
    }
  }

  const bankRecipientFormDetails = useMemo(() => {
    return splitBankRecipientData(formValues)
  }, [formValues])

  if (isLoading && bankMetadataFetching && documentTypesFetching) {
    return <CoreLoader />
  }

  return (
    <div className='recipient-edit-wrapper'>
      <form className='styled-form' noValidate>
        <div className='recipient-edit-steps'>
          <CoreSteps
            maxStep={2}
            step={step}
            title={
              step === 1
                ? tr('FRONTEND.RECIPIENTS.EDIT.INFO.PAYMENT', 'Payment information')
                : tr('FRONTEND.RECIPIENTS.EDIT.INFO.PERSONAL', 'Personal information')
            }
          />
        </div>
        <div className='recipient-form-title'>
          {step === 1
            ? tr('FRONTEND.RECIPIENTS.EDIT.INFO.PAYMENT', 'Payment information')
            : tr('FRONTEND.RECIPIENTS.EDIT.INFO.PERSONAL', 'Personal information')}
        </div>
        <div className='form-elements-container'>
          {isValidObject(formValues) &&
            Object.entries(
              step === 1 ? bankRecipientFormDetails.paymentInformation : bankRecipientFormDetails.personalInformation
            ).map((meta, index) => {
              const [key] = meta

              if (obsoleteFields.includes(key)) return null

              switch (key) {
                case InstrumentFieldEnum.COUNTRY_CODE:
                case InstrumentFieldEnum.HOLDER_NAME:
                  return null

                case 'holderDateOfBirth':
                  return (
                    <CoreInput
                      key={key + index}
                      type='date'
                      name={key}
                      data-test={key}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )

                case 'holderPhone':
                  return (
                    <CoreInput
                      key={key + index}
                      type='phone'
                      name={key}
                      data-test={key}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )

                case 'bankAddress.countryCode':
                  return (
                    <CoreSelect
                      key={key + index}
                      type='country'
                      data-test={key}
                      options={isValidArray(filteredCountries) ? filteredCountries : []}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )

                case 'holderAddress.countryCode':
                  return (
                    <CoreSelect
                      key={key + index}
                      type='country'
                      data-test={key}
                      options={isValidArray(countryOptions) ? countryOptions : []}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )

                case 'entityType':
                  return (
                    <CoreSelect
                      key={key + index}
                      type='default'
                      data-test={key}
                      isSearchable={false}
                      preselectedValue={{
                        value: 'COMPANY',
                        label: tr('FRONTEND.SIGNUP.ACCOUNT_TYPES.COMPANY', 'Corporate Account')
                      }}
                      options={[
                        {
                          value: 'INDIVIDUAL',
                          label: tr('FRONTEND.SIGNUP.ACCOUNT_TYPES.INDIVIDUAL', 'Individual Account')
                        },
                        {
                          value: 'COMPANY',
                          label: tr('FRONTEND.SIGNUP.ACCOUNT_TYPES.COMPANY', 'Corporate Account')
                        }
                      ]}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )

                case 'documentType':
                  return (
                    <CoreSelect
                      key={key + index}
                      type='default'
                      data-test={key}
                      options={documentTypesOptions}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )

                case 'accountType':
                  return (
                    <CoreSelect
                      key={key + index}
                      type='default'
                      data-test={key}
                      options={accountTypeOptions()}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )

                case 'bankName':
                  return isValidArray(providedBanks) ? (
                    <CoreSelect
                      key={key + index}
                      type='default'
                      options={providedBanksOptions()}
                      label={tr('FRONTEND.RECIPIENTS.LABELS.BANK_NAME', 'Bank Name')}
                      placeholder={tr('FRONTEND.RECIPIENTS.LABELS.BANK_NAME', 'Bank Name')}
                      {...formValues.registerInput('bankName', handleBankChange)}
                      error={formValues.getError('bankName')}
                    />
                  ) : (
                    <CoreInput
                      key={key + index}
                      type='text'
                      value={formValues.getValue('bankName')}
                      label={tr(`FRONTEND.RECIPIENTS.LABELS.BANK_NAME`, 'Bank Name')}
                      placeholder={tr('FRONTEND.RECIPIENTS.LABELS.BANK_NAME', 'Bank Name')}
                      data-test='bank-code'
                      {...formValues.registerInput('bankName')}
                    />
                  )

                case 'bankCode':
                  return (
                    <CoreInput
                      key={key + index}
                      type='text'
                      readOnly={isValidArray(providedBanks)}
                      value={formValues.getValue('bankCode')}
                      label={tr(`FRONTEND.RECIPIENTS.LABELS.BANK_CODE`, 'Bank Code')}
                      placeholder={tr('FRONTEND.RECIPIENTS.LABELS.BANK_NAME', 'Bank Code')}
                      data-test='bank-code'
                      {...formValues.registerInput('bankCode')}
                    />
                  )
                default:
                  return (
                    <CoreInput
                      key={key + index}
                      type='text'
                      data-test={key}
                      label={getExternalBankAccountFieldLabel(countryCode, recipientKeyToCode(key))}
                      tooltip={tr(formValues.getTooltip(key), '')}
                      {...formValues.registerInput(key)}
                    />
                  )
              }
            })}
        </div>

        <ErrorDisplay error={[saveRecipientError]} />

        <div className='recipient-edit-actions'>
          {step === 2 && (
            <CoreButton
              data-test='save-button'
              type='button'
              variation='tertiary'
              size='large'
              text={tr('FRONTEND.RECIPIENTS.BUTTON.BACK', 'Back')}
              className='save-button'
              onClick={() => {
                setStep(1)
              }}
              disabled={saveRecipientLoading}
            />
          )}
          <CoreButton
            data-test='save-button'
            type='button'
            variation='primary'
            size='large'
            fullWidth
            text={tr('FRONTEND.RECIPIENTS.BUTTON.SAVE', 'Save')}
            className='save-button'
            onClick={async e => {
              e.preventDefault()
              await handleSubmit()
            }}
            isLoading={saveRecipientLoading}
          />
          {step === 1 && (
            <CoreButton
              data-test='save-button'
              type='button'
              variation='tertiary'
              size='large'
              text={tr('FRONTEND.RECIPIENTS.BUTTON.NEXT', 'Next')}
              className='save-button'
              onClick={() => {
                setStep(2)
              }}
              disabled={saveRecipientLoading}
            />
          )}
        </div>
      </form>
    </div>
  )
}
