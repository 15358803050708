import CorePreviewLine from "../../../components/CorePreviewLine";

import { formatFloat, formatMoney, getCurrencyDecimals, tr } from "mmfintech-commons";
import { CheckoutDetails, TransactionViewPortal } from "mmfintech-commons-types"
import moment from "moment";

import './styled/payoutPreview.scss'

export const PayoutPreview = ({ previewData, checkoutDetails, trn }
    : { previewData: any, checkoutDetails: CheckoutDetails, trn?: TransactionViewPortal }) => {

    const { id, reqTime } = trn || {}
    const { fee, feeCurrency, walletAmount, walletCurrency } = previewData || {}
    const { customerName, customerEmail } = checkoutDetails || {}

    return (
        <div className="payout-preview-wrapper">
            <div className="payout-amount-section">
                <div className="amount-label">{tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.AMOUNT', 'Amount')}</div>
                <div className="amount-content">
                    {walletAmount > 0 && walletCurrency?.length ? formatFloat(walletAmount, getCurrencyDecimals(walletCurrency)) : null}
                    <span className="amount-currency">{walletAmount > 0 && walletCurrency}</span>
                </div>
            </div>

            <div className="payout-details-section">

                <CorePreviewLine
                    className={'transaction-payout-single-content'}
                    title={tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.CUSTOMER_NAME', 'Customer name')}
                    text={customerName}
                />
                <CorePreviewLine
                    className={'transaction-payout-single-content'}
                    title={tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.CUSTOMER_EMAIL', 'Customer email')}
                    text={customerEmail}
                />
                <CorePreviewLine
                    className={'transaction-payout-single-content'}
                    title={tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.TRANSACTION_ID', 'Transaction ID')}
                    text={id?.toString()}
                />
                <CorePreviewLine
                    className={'transaction-payout-single-content'}
                    title={tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.DATE', 'Date')}
                    text={moment(reqTime).format('DD/MM/YYYY')}
                />
                {fee > 0 ? (
                    <CorePreviewLine
                        className={'transaction-payout-single-content'}
                        title={tr('FRONTEND.TRANSACTIONS.PAYOUT_MODAL.FEE', 'Fee')}
                        text={formatMoney(fee, feeCurrency)}
                    />
                ) : null}
            </div>

        </div>
    )
}
