import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import cn from 'classnames'

import { useDebounce } from '@utils'
import { useInfiniteScroll } from '@hooks'
import { paths, useLazyGetPaymentAccountsFilteredQuery } from 'mmfintech-backend-api'
import { currenciesAlpha3, formatFloat, getCurrencyDecimals, isValidArray, tr } from 'mmfintech-commons'

import { CoreCurrencyIcon } from '@components'

import { AccountBalanceResponse, AccountStatusEnum } from 'mmfintech-commons-types'

import './accountsSection.scss'

import ChevronIcon from '@images/icons/chevron-up-black.svg?react'
import MovingArrow from '@images/icons/moving-arrow.svg?react'

export const AccountsSection = () => {
  const {
    combinedData: activeAccounts,
    isFetching,
    readMore,
    scrollMore
  } = useInfiniteScroll(useLazyGetPaymentAccountsFilteredQuery, {
    filter: {
      accountStatuses: [AccountStatusEnum.ACTIVE]
    },
    size: 20
  })

  const carouselRef = useRef(null)
  const [selectable, setSelectable] = useState<boolean>(true)
  const [selectedAccount, setSelectedAccount] = useState<AccountBalanceResponse>(null)
  const [carouselScroll, setCarouselScroll] = useState(0)
  const history = useHistory()

  const handleScroll = useDebounce(() => {
    scrollMore && scrollMore()
  }, 300)

  const prev = () => {
    carouselRef.current && carouselRef.current.scrollBy({ left: -400, behavior: 'smooth' })
    if (carouselRef.current.scrollLeft - 400 < 0) {
      setCarouselScroll(0)
    } else {
      setCarouselScroll(carouselRef.current.scrollLeft - 400)
    }
  }

  const next = () => {
    carouselRef.current && carouselRef.current.scrollBy({ left: 400, behavior: 'smooth' })
    if (carouselRef.current.scrollLeft + carouselRef.current.clientWidth + 400 > carouselRef.current.scrollWidth) {
      setCarouselScroll(carouselRef.current.scrollWidth)
    } else {
      setCarouselScroll(carouselRef.current.scrollLeft + carouselRef.current.clientWidth + 400)
    }
  }

  const onMouseDown = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    const drag = (mouseOverEvent: React.MouseEvent<HTMLElement>) => {
      document.body.style.cursor = 'pointer'
      setSelectable(false)
      carouselRef.current.scroll({
        left: carouselRef.current.scrollLeft - mouseOverEvent.movementX
      })
      if (carouselRef.current.scrollLeft - mouseOverEvent.movementX < 0) {
        setCarouselScroll(0)
      } else if (
        carouselRef.current.scrollLeft - mouseOverEvent.movementX + carouselRef.current.clientWidth + 5 >
        carouselRef.current.scrollWidth
      ) {
        setCarouselScroll(carouselRef.current.scrollWidth)
      } else {
        setCarouselScroll(carouselRef.current.scrollLeft - mouseOverEvent.movementX)
      }
    }
    carouselRef.current.addEventListener('mousemove', drag)
    const cancelDrag = () => {
      carouselRef.current?.removeEventListener('mousemove', drag)
      document.body.style.cursor = 'default'
      setTimeout(() => {
        setSelectable(true)
      }, 200)
    }
    carouselRef.current.addEventListener('mouseup', cancelDrag, { once: true })
    carouselRef.current.addEventListener('mouseleave', cancelDrag, { once: true })
  }, [])

  const onScroll = useCallback(e => {
    e.preventDefault()
    if (e.target.scrollLeft + e.target.clientWidth + 20 >= e.target.scrollWidth && !isFetching) {
      handleScroll()
    }
  }, [])

  useEffect(() => {
    void readMore({ size: 20, page: 0 })
    carouselRef.current.addEventListener('mousedown', onMouseDown)
    carouselRef.current.addEventListener('scroll', onScroll)

    return () => {
      carouselRef.current?.removeEventListener('mousedown', onMouseDown)
      carouselRef.current?.removeEventListener('scroll', onScroll)
    }
  }, [])

  useEffect(() => {
    if (isValidArray(activeAccounts)) {
      setSelectedAccount(activeAccounts[0])
    }
  }, [activeAccounts])

  return (
    <div
      data-test='carousel-cards-main-test'
      className={cn('carousel-cards-main', {
        'left-blur': carouselScroll != 0,
        'right-blur': carouselScroll !== carouselRef.current?.scrollWidth
      })}>
      <div className='carousel-cards-arrows-wrapper'>
        <span data-test='account-header-title-test' className='account-header-title'>
          {tr('FRONTEND.ACCOUNTS.MY_ACCOUNTS', 'My accounts')}
        </span>
        {isValidArray(activeAccounts) && activeAccounts.length > 2 && (
          <div className='carousel-arrows'>
            <div
              className={cn('carousel-cards-arrow', {
                active: carouselScroll != 0
              })}
              onClick={e => {
                e.preventDefault()
                prev()
              }}>
              <MovingArrow />
            </div>
            <div
              className={cn('carousel-cards-arrow rotated', {
                active: carouselScroll !== carouselRef.current?.scrollWidth
              })}
              onClick={e => {
                e.preventDefault()
                next()
              }}>
              <MovingArrow />
            </div>
          </div>
        )}
      </div>
      <div data-test={`carousel-cards-container-test`} className='carousel-cards-container' ref={carouselRef}>
        {isValidArray(activeAccounts) &&
          activeAccounts.map((account, i) => {
            const { currencyCode, balance, id, dedicatedMerchantBankAccounts, name } = account || {}
            const { iban } =
              ((isValidArray(dedicatedMerchantBankAccounts) ? dedicatedMerchantBankAccounts[0] : {}) as any) || {}

            return (
              <div
                data-test={`carousel-cards-wrapper-${i}-test`}
                key={id}
                className={cn('carousel-cards-wrapper', { active: activeAccounts.indexOf(selectedAccount) == i })}
                onClick={() => {
                  if (selectable) {
                    history.push({
                      pathname: paths.banking.accounts.list(),
                      state: { accountId: id }
                    })
                  }
                }}>
                <div style={{ width: '100%' }}>
                  <div data-test={`carousel-cards-header-${i}-test`} className='carousel-cards-header'>
                    <div className='carousel-cards-header-left'>
                      {currencyCode && <CoreCurrencyIcon currency={currencyCode} size='4rem' circle />}
                      <div
                        data-test={`carousel-cards-currency-title-${i}-test`}
                        className='carousel-cards-currency-title'>
                        <span
                          onMouseOver={event => {
                            const target = event.target as HTMLSpanElement
                            event.stopPropagation()
                            if (target.clientWidth > target.parentElement.clientWidth) {
                              target.style.transform = `translateX(${-(
                                target.clientWidth - target.parentElement.clientWidth
                              )}px)`
                            }
                          }}
                          onMouseLeave={event => {
                            const target = event.target as HTMLSpanElement
                            target.style.transform = `translateX(0px)`
                          }}>
                          {name || currenciesAlpha3[currencyCode]?.name}
                        </span>
                      </div>
                    </div>
                    <ChevronIcon />
                  </div>

                  <div data-test={`carousel-cards-content-${i}-test`} className='carousel-cards-content'>
                    {formatFloat(balance, getCurrencyDecimals(currencyCode))}{' '}
                    <span className='carousel-cards-content-currency'>{currencyCode}</span>
                  </div>
                </div>

                <div data-test={`carousel-cards-content-${i}-test`} className='carousel-cards-content-iban'>
                  {iban ? (
                    <>
                      <div className='carousel-cards-label-wrapper'>
                        <span>{tr('FRONTEND.ACCOUNTS.IBAN', 'IBAN')}</span>
                      </div>
                      <span>{iban}</span>
                    </>
                  ) : (
                    <>
                      <div className='carousel-cards-label-wrapper'>
                        <span>{tr('FRONTEND.ACCOUNTS.ID', 'ID')}</span>
                      </div>
                      <span>{id}</span>
                    </>
                  )}
                </div>
              </div>
            )
          })}
      </div>

      {/* <div className='carousel-cards-dots'>
        {isValidArray(accounts) &&
          accounts.length > 2 &&
          accounts.length > 1 &&
          accounts.map((_, index) => {
            return (
              <div
                className={cn('carousel-cards-dots-element', { active: accounts.indexOf(selectedAccount) == index })}
                key={'card-dot' + index}
                onClick={() => selectAccountElement(0, index)}>
                <span> </span>
              </div>
            )
          })}
      </div> */}
    </div>
  )
}
