import { useHistory } from 'react-router-dom'
import { useContext, useState } from 'react'

import cn from 'classnames'

import {
  endpoints,
  formatTransactionDescription,
  getTransactionStatusName,
  useFileDownloader,
  useGetMerchantQuery,
  usePayoutInitiateMutation,
  usePayoutPreviewMutation,
  useRefundInitiateMutation,
  useRefundPreviewMutation,
  useTransactionDetailsQry
} from 'mmfintech-backend-api'
import { formatDate, formatFloat, getCurrencyDecimals, GlobalContext, isValidString, tr } from 'mmfintech-commons'
import { PaymentFlowEnum, PaymentMethodEnum, TransactionDirectionEnum, TransactionStatusEnum } from 'mmfintech-commons-types'
import { ErrorDisplay, Preloader } from 'mmfintech-portal-commons'

import { CoreButton, CoreStatusView } from '@components'
import CorePreviewLine from '../../components/CorePreviewLine'

import BackArrowIcon from '@images/icons/transaction-details-back-arrow-left-icon.svg?react'
import TransactionsExportDocumentIcon from '@images/icons/transaction-export-icon.svg?react'
import RefundIcon from '@images/icons/refund-icon.svg?react'
import PayoutIcon from '@images/icons/arrow-up.svg?react'

import '@views/transactions/styled/transactionsDetails.scss'
import fileDownload from 'js-file-download'
import { HashLink } from './elements/HashLink'
import ReactTooltip from 'react-tooltip'
import { cutCryptoAddress } from '@utils'
import { RefundModal } from './elements/RefundModal'
import { PayoutModal } from './elements/PayoutModal'

function TransactionDetails({ isModal = false, transactionId = null }) {
  const [currentTransactionId, setCurrentTransactionId] = useState(transactionId)

  const { transaction, transactionError, transactionFetching } = useTransactionDetailsQry(currentTransactionId)
  const { data: merchant } = useGetMerchantQuery()
  const { capabilities } = merchant || {}
  const { enablePayouts, enableRefunds } = capabilities || {}

  const history = useHistory()
  const downloader = useFileDownloader()

  const { modalShow } = useContext(GlobalContext)

  const [_refundPreview, { reset: resetRefundPreview }] = useRefundPreviewMutation({
    fixedCacheKey: 'refund-preview'
  })
  const [_refundInit, { reset: resetRefundInitiation }] = useRefundInitiateMutation({
    fixedCacheKey: 'refund-init'
  })
  const [_payoutPreview, { reset: resetPayoutPreview }] = usePayoutPreviewMutation({
    fixedCacheKey: 'payout-preview'
  })
  const [_payoutInit, { reset: resetPayoutInitiation }] = usePayoutInitiateMutation({
    fixedCacheKey: 'payout-init'
  })

  const {
    id,
    amount: transactionAmount,
    currency: transactionCurrency,
    status,
    reqTime,
    direction,
    reference,
    feeDetails,
    accountName,
    paymentMethod,
    bankwireDetails,
    transactionCategory,
    trnTypeLocalizationKey,
    emoneyInternalTransferDetails,
    externalWalletDetails,
    cryptoDetails,
    parentTransactionId,
    instrumentDetails,
    foreignTransactionId,
    statusCodeLocalizationKey,
    statusCodeDefaultDescription,
    paymentFlow,
    processingAmount,
    processingCurrency,
    checkoutDetails,
    cardPaymentDetails
  } = transaction || {}

  const { amount: feeAmount, currency: feeCurrency } = feeDetails || {}
  const {
    counterpartyName,
    counterpartyIban,
    counterpartyAccountNumber,
    counterpartyBankCode,
    counterpartyBranchCode
  } = bankwireDetails || {}

  const { transactionAmount: cardPaymentAmount, transactionCurrency: cardPaymentCurrency } = cardPaymentDetails || {}

  const amount = cardPaymentAmount ? cardPaymentAmount : transactionAmount
  const currency = cardPaymentAmount ? cardPaymentCurrency : transactionCurrency

  const showProcessingAmount = cardPaymentAmount && cardPaymentAmount != transactionAmount

  const { accountName: accountFrom, stAccountId: accountFromId } = emoneyInternalTransferDetails || {}
  const { name: externalWalletName, identifier: externalWalletIdentifier } = externalWalletDetails || {}
  const { counterpartyAddress, blockchainExplorerUrl, txHash } = cryptoDetails || {}
  const { customerName: checkoutCustomerName } = checkoutDetails || {}

  const showAdditionalActions = (
    [
      PaymentMethodEnum.BANKWIRE,
      PaymentMethodEnum.INSTANT_BANK_TRANSFER,
      PaymentMethodEnum.INTERAC
    ] as string[]
  ).includes(paymentMethod) &&
    direction === TransactionDirectionEnum.DEPOSIT &&
    ([PaymentFlowEnum.CHECKOUT, PaymentFlowEnum.DEPOSIT] as string[]).includes(paymentFlow) &&
    status === TransactionStatusEnum.PROCESSED

  const showRefund = showAdditionalActions && enableRefunds
  const showPayout = showAdditionalActions && enablePayouts

  const handlePayout = () => {
    modalShow({
      header: tr('FRONTEND.TRANSACTIONS.PAYOUT_BUTTON', 'Payout'),
      content: <PayoutModal transaction={transaction} />,
      options: {
        onClose: () => {
          resetPayoutPreview()
          resetPayoutInitiation()
        }
      }
    })
  }

  const handleRefund = () => {
    modalShow({
      header: tr('FRONTEND.TRANSACTIONS.REFUND_BUTTON', 'Refund'),
      content: <RefundModal transaction={transaction} />,
      options: {
        onClose: () => {
          resetRefundPreview()
          resetRefundInitiation()
        }
      }
    })
  }

  const handleTransactionReceiptClick = (transactionId: number) => {
    if (transactionId > 0) {
      void downloader.download({
        url: endpoints.transactions.getTransferReceipt(transactionId),
        method: 'GET',
        onSuccess: (data: any, filename?: string) => {
          fileDownload(data, filename || `transaction-receipt-${transactionId}.pdf`, 'application/pdf')
        }
      })
    }
  }

  return (
    <div className={cn('transaction-details-container', { 'is-modal': isModal })}>
      {!isModal && (
        <div className='transaction-details-header-wrapper'>
          <CoreButton
            text={tr('FRONTEND.TRANSACTIONS.DETAILS.BUTTON.BACK', 'Back')}
            data-test='transaction-details-back-button'
            LeftIcon={<BackArrowIcon />}
            style={{ marginRight: '2rem' }}
            className='transaction-details-header-back-button'
            variation='secondary'
            onClick={() => history.goBack()}
          />
          {paymentMethod === PaymentMethodEnum.BANKWIRE && (
            <CoreButton
              data-test='transaction-details-PDF-download-button'
              text={tr('FRONTEND.TRANSACTIONS.DETAILS.BUTTON.DOWNLOAD', 'Download as PDF')}
              LeftIcon={<TransactionsExportDocumentIcon />}
              onClick={() => handleTransactionReceiptClick(id)}
              variation='secondary'
              className='transaction-details-header-PDF-button'
            />
          )}
        </div>
      )}
      {!isModal && (
        <h3 data-test='transaction-details-title'>
          {tr('FRONTEND.TRANSACTIONS.DETAILS.TITLE', 'Transaction details')}
        </h3>
      )}
      {transactionFetching ? <Preloader /> : transactionError ? <ErrorDisplay error={transactionError} /> : null}
      <div className='transaction-details-wrapper'>
        {amount && (
          <div className='transaction-details-content-header-wrapper'>
            <span data-test='transaction-details-amount-label' className='transaction-details-header'>
              {tr('FRONTEND.TRANSACTIONS.DETAILS.AMOUNT', 'Amount')}
            </span>
            <span data-test='transaction-details-PDF-amount-value' className='value'>
              <span data-test='amount'>
                {formatFloat(
                  amount * (direction === TransactionDirectionEnum.WITHDRAW ? -1 : 1),
                  getCurrencyDecimals(currency)
                )}{' '}
                <span className='transaction-currency'>{currency}</span>
              </span>
              <div className='status'>
                <CoreStatusView
                  fade={true}
                  data-test='transaction-details-status'
                  status={getTransactionStatusName(status) as TransactionStatusEnum}
                />
              </div>
            </span>
          </div>
        )}

        {!transactionFetching && (
          <div className='transaction-details-content-wrapper'>
            <div className='transaction-details-inner'>
              <CorePreviewLine
                className={'transaction-details-single-content'}
                iconType={'category-transaction'}
                title={tr('FRONTEND.TRANSACTIONS.DETAILS.TYPE', 'Type')}
                text={tr(trnTypeLocalizationKey, transactionCategory?.toLocaleLowerCase().replace(/_/g, ' '))}
              />
              <CorePreviewLine
                className={'transaction-details-single-content'}
                iconType={'date'}
                title={tr('FRONTEND.TRANSACTIONS.DETAILS.DATE', 'Date')}
                text={formatDate(reqTime)}
              />
              {counterpartyName && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'recipient'}
                  title={
                    direction === TransactionDirectionEnum.WITHDRAW
                      ? tr('FRONTEND.TRANSACTIONS.DETAILS.TO', 'To')
                      : tr('FRONTEND.TRANSACTIONS.DETAILS.FROM', 'From')
                  }
                  text={counterpartyName}
                />
              )}
              {accountName && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'account'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.ACCOUNT', 'Account')}
                  text={accountName}
                />
              )}
              {checkoutCustomerName && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'account'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.CUSTOMER_NAME', 'Customer Name')}
                  text={checkoutCustomerName}
                />
              )}
              {feeDetails && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'percentage'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.FEE', 'Fee')}
                  text={
                    <p>
                      {formatFloat(feeAmount, getCurrencyDecimals(feeCurrency))}{' '}
                      <span className='amount-currency'>{feeCurrency}</span>
                    </p>
                  }
                />
              )}
              {id && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'transaction-id'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.TRANSACTION_ID', 'Transaction ID')}
                  text={id.toString()}
                />
              )}
              {!!showProcessingAmount && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'transaction-id'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.PROCESSED_AMOUNT', 'Processed amount')}
                  text={
                    <p>
                      {formatFloat(transactionAmount, getCurrencyDecimals(transactionCurrency))}{' '}
                      <span className='amount-currency'>{transactionCurrency}</span>
                    </p>
                  }
                />
              )}
              {paymentMethod && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'method'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.METHOD', 'Method')}
                  text={paymentMethod.toLocaleLowerCase().replace(/_/g, ' ')}
                />
              )}
              {reference && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.REFERENCE', 'Reference')}
                  text={reference}
                />
              )}
              {counterpartyIban && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.IBAN', 'IBAN')}
                  text={counterpartyIban}
                />
              )}
              {counterpartyAccountNumber && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.ACCOUNT_NUMBER', 'Account Number')}
                  text={counterpartyAccountNumber}
                />
              )}
              {counterpartyBankCode && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.BANK_CODE', 'Bank code')}
                  text={counterpartyBankCode}
                />
              )}
              {counterpartyBranchCode && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.BRANCH_CODE', 'Branch code')}
                  text={counterpartyBranchCode}
                />
              )}
              {trnTypeLocalizationKey === 'MERCHANTS.TRANSACTION.TYPE.INTRA_ACCOUNT_TRANSFER' &&
                emoneyInternalTransferDetails && (
                  <CorePreviewLine
                    className={'transaction-details-single-content'}
                    iconType={'reference'}
                    title={tr('FRONTEND.TRANSACTIONS.DETAILS.INTERNAL.TRANSFER.ACCOUNT.FROM', 'From')}
                    text={`${accountFrom} - ID: ${accountFromId}`}
                  />
                )}

              {externalWalletName && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.WALLET_NAME', 'External wallet name')}
                  text={externalWalletName}
                />
              )}

              {externalWalletIdentifier && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.WALLET_ID', 'External wallet ID')}
                  text={externalWalletIdentifier}
                />
              )}

              {txHash && (
                <CorePreviewLine
                  className={'transaction-details-single-content hash'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.TRN_HASH', 'Transaction hash')}
                  text={<HashLink hash={cutCryptoAddress(txHash)} link={blockchainExplorerUrl} />}
                  value={txHash}
                  isCopyVisible={true}
                />
              )}

              {counterpartyAddress && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.CRYPTO_ADDRESS', 'Address')}
                  text={counterpartyAddress}
                />
              )}

              {paymentFlow !== 'TRANSFER' && parentTransactionId && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.RELATED_TRANSACTION', 'Related Transaction')}
                  text={
                    <p
                      style={{ textDecoration: 'none', color: 'blue', cursor: 'pointer' }}
                      onClick={() => setCurrentTransactionId(parentTransactionId)}>
                      {parentTransactionId}
                    </p>
                  }
                />
              )}
              {(trnTypeLocalizationKey === 'MERCHANTS.TRANSACTION.TYPE.EMONEY_TRANSFER_INCOMING' ||
                trnTypeLocalizationKey === 'MERCHANTS.TRANSACTION.TYPE.EMONEY_TRANSFER_OUTGOING') && (
                  <CorePreviewLine
                    className={'transaction-details-single-content'}
                    iconType={'reference'}
                    title={tr('FRONTEND.TRANSACTIONS.DETAILS.EMAIL', 'Email')}
                    text={instrumentDetails}
                  />
                )}

              {foreignTransactionId && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.FOREIGN_ID', 'Foreign ID')}
                  text={foreignTransactionId}
                />
              )}
              {processingAmount && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.DETAILS.PROCESSING_AMOUNT', 'Processing amount')}
                  text={
                    <p>
                      {formatFloat(processingAmount, getCurrencyDecimals(processingCurrency))}{' '}
                      <span className='amount-currency'>{processingCurrency}</span>
                    </p>
                  }
                />
              )}

              {statusCodeDefaultDescription && (
                <CorePreviewLine
                  className={'transaction-details-single-content'}
                  iconType={'reference'}
                  title={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS_DESCRIPTION', 'Fail reason')}
                  text={
                    isValidString(statusCodeLocalizationKey)
                      ? tr(statusCodeLocalizationKey, statusCodeDefaultDescription)
                      : statusCodeDefaultDescription
                  }
                />
              )}
            </div>
            <CorePreviewLine
              className={'transaction-details-description-content'}
              iconType={'description'}
              title={tr('FRONTEND.TRANSACTIONS.DETAILS.DESCRIPTION', 'Description')}
              text={formatTransactionDescription(transaction)}
            />

            <ReactTooltip
              delayHide={1000}
              id='preview-line-copy'
              place={'top'}
              type='dark'
              effect='solid'
              class='copy-text-tooltip'
              padding='8px 10px'
              isCapture={true}
            />
          </div>
        )}

        {!transactionFetching && <div className='transaction-details-actions-wrapper'>
          {isModal &&
            <div className='transaction-details-footer-PDF-button'>
              {(paymentMethod === PaymentMethodEnum.BANKWIRE ||
                paymentMethod === PaymentMethodEnum.SWIFT_BANKWIRE ||
                paymentMethod === PaymentMethodEnum.LOCAL_BANKWIRE ||
                paymentMethod === PaymentMethodEnum.SEPA_BANKWIRE) && (
                  <CoreButton
                    data-test='transaction-details-PDF-download-button'
                    text={tr('FRONTEND.TRANSACTIONS.DETAILS.BUTTON.DOWNLOAD', 'Download as PDF')}
                    LeftIcon={<TransactionsExportDocumentIcon />}
                    onClick={() => handleTransactionReceiptClick(id)}
                    isLoading={downloader.fetching}
                    variation='tertiary'
                    size='large'
                    className='transaction-details-header-PDF-button'
                  />

                )}
              {
                showRefund && (
                  <CoreButton
                    data-test='transaction-refund-button'
                    text={tr('FRONTEND.TRANSACTIONS.REFUND_BUTTON', 'Refund')}
                    variation='primary'
                    LeftIcon={<RefundIcon />}
                    size='large'
                    onClick={handleRefund}
                  />
                )
              }
              {
                showPayout && (
                  <CoreButton
                    className='payout-button'
                    data-test='transaction-payout-button'
                    text={tr('FRONTEND.TRANSACTIONS.PAYOUT_BUTTON', 'Payout')}
                    variation='primary'
                    LeftIcon={<PayoutIcon />}
                    size='large'
                    onClick={handlePayout}
                  />
                )
              }
            </div>
          }
        </div>}
      </div>
    </div>
  )
}

export default TransactionDetails
