import React, { FunctionComponent, SVGProps, useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import {
  api,
  paths,
  useGetMerchantQuery,
  useGetMerchantsForSwitchQuery,
  useGetProfileQuery,
  useLogoutQry,
  useSwitchMerchantMutation
} from 'mmfintech-backend-api'
import { isValidArray, tr, useDropDownMenu } from 'mmfintech-commons'
import cn from 'classnames'

import './coreMenu.scss'

import LogoImage from '@images/icons/the-kingdom-bank-logo.svg'
import MenuIcon from '@images/icons/menu-collapse-icon.svg'
import MenuCollapsedIcon from '@images/icons/the-kingdom-bank-collapsed-logo.svg'
import UpArrowIcon from '@images/icons/up-arrow-icon.svg?react'
import DownArrowIcon from '@images/icons/down-arrow-icon.svg?react'
import SettingsIcon from '@images/icons/settings-icon.svg?react'
import LogoutIcon from '@images/icons/logout-icon.svg?react'
import UserIcon from '@images/icons/user-icon.svg?react'
import SearchIcon from '@images/icons/search-icon.svg?react'
import DeveloperIcon from '@images/icons/developers-icon.svg?react'
import { CoreButton, CoreInput, CoreProfileAvatar } from '@components'
import { useAppDispatch, useMatchMedia } from '@hooks'
import { useDebounce } from '@utils'
import { breakpoints, coreMenuItems } from '@constants'
import { OnboardingBanner } from '@views/onboarding/OnboardingBanner'
import { CustomerRoleEnum } from 'mmfintech-commons-types'

export interface MenuItem {
  imageSrc: string
  Image: FunctionComponent<SVGProps<SVGSVGElement>>
  label: string
  path?: string
  localization?: string
  onClick?: () => void
  toolTip?: string
}

export const CoreMenu = () => {
  const history = useHistory()
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const { data: merchant } = useGetMerchantQuery()
  const { data: profile } = useGetProfileQuery(null)
  // const { data: merchantLogo } = useGetLogoQuery()
  const { visible, toggleDropDown, setRef: setSubAccountRef } = useDropDownMenu({ useOptionalRef: true })
  const { merchantName, merchantId, apiEnabled, capabilities } = (merchant as any) || {}
  const { allowReferrals, forexAccountRequestStatus } = capabilities || {}
  const { customerRole, isCardIssuingEnabled } = (profile as any) || {}
  const {
    visible: visibleTemp,
    toggleDropDown: toggleDropDownSettings,
    setRef: setSettingsRef
  } = useDropDownMenu({ useOptionalRef: true })
  const [terms, setSearchTerm] = useState<string>('')
  const { data: subAccounts }: any = useGetMerchantsForSwitchQuery(null)
  const [filteredAccounts, setFilteredAccounts] = useState<any>(null)
  const dispatch = useAppDispatch()
  const autoCollapse = useMatchMedia({ breakpoint: breakpoints.MENU_CLOSE_WIDTH, prefix: 'max' })

  const { logout } = useLogoutQry(true)

  const isOwnerOrAdministrator = () =>
    customerRole === CustomerRoleEnum.OWNER || customerRole === CustomerRoleEnum.ADMINISTRATOR
  const isDevelopersMenuVisible = () => !!apiEnabled && isOwnerOrAdministrator()
  const isForexEnabled = () => forexAccountRequestStatus !== 'NOT_REQUESTED' && isOwnerOrAdministrator()
  const items = coreMenuItems(customerRole, isCardIssuingEnabled, merchant)

  const [switchMerchant, { isLoading: switchMerchantLoading }] = useSwitchMerchantMutation()
  const handleSwitchMerchant = async (merchantId: number) => {
    if (!switchMerchantLoading && merchant?.merchantId !== merchantId) {
      try {
        await switchMerchant({ merchantId }).unwrap()
        dispatch(api.util.resetApiState())
      } catch (_error) {}
    }
  }

  const handleSearch = useDebounce(term => {
    const query = term.replace(/\s/g, '').toLowerCase()
    const filtered =
      subAccounts && subAccounts?.filter(account => account.name.toLowerCase().replace(/\s/g, '').includes(query))
    setFilteredAccounts(filtered)
  }, 500)

  const handleChange = value => {
    setSearchTerm(value)
    handleSearch(value)
  }

  useEffect(() => {
    if (isValidArray(subAccounts) && merchantId) {
      const filteredAccounts = subAccounts && subAccounts?.filter(account => account.merchantId !== merchantId)
      setFilteredAccounts(filteredAccounts)
    }
  }, [subAccounts, merchantId])

  useEffect(() => {
    setCollapsed(autoCollapse)
  }, [autoCollapse])

  const handleLogoutClick = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    void logout()
    return false
  }

  return (
    <div className='core-menu'>
      <div className={`core-menu-wrapper ${collapsed ? 'collapsed' : ''}`}>
        <div style={{ width: '100%' }}>
          <div className='menu-header'>
            <img
              data-test='menu-header-collapse-icon'
              src={MenuIcon}
              className='collapse-icon'
              onClick={() => setCollapsed(!collapsed)}
              alt=''
            />
            <img
              data-test='menu-header-image'
              className='menu-logo'
              onClick={() => history.push(paths.dashboard())}
              src={collapsed ? MenuCollapsedIcon : LogoImage}
              alt='missing logo'
            />
          </div>
          <ul className='menu-body'>
            {items.map(({ label, path, onClick, imageSrc, localization, toolTip }) => {
              return (
                <div
                  key={label}
                  className='button-wrapper'
                  style={{
                    display:
                      (label === 'Invite & Earn' && !allowReferrals) || (label === 'Forex' && !isForexEnabled())
                        ? 'none'
                        : ''
                  }}>
                  <NavLink
                    data-for={`side-menu${toolTip}`}
                    data-tip={toolTip}
                    data-test={`menu-links-${label}`}
                    to={path}
                    onClick={onClick || (() => history.push(path))}
                    className='menu-item-wrapper'>
                    <img data-test={`menu-link-image-${label}`} src={imageSrc} alt='' />
                    <span data-test={`menu-links-label-${label}`} className='menu-item'>
                      {tr(localization, label)}
                    </span>
                  </NavLink>
                  <ReactTooltip
                    id={`side-menu${toolTip}`}
                    textColor='black'
                    backgroundColor='white'
                    effect='solid'
                    className='custom-tool-tip-component'
                    padding='8px 6px'
                    place={'right'}
                    disable={!collapsed}>
                    {toolTip}
                  </ReactTooltip>
                </div>
              )
            })}
          </ul>
        </div>
        <div className='menu-bottom-section'>
          <div className='menu-onboarding-banner-wrapper'>
            <OnboardingBanner collapsed={collapsed} />
          </div>
          <div className={cn('merchant-panel', { collapsed: collapsed })}>
            <div className='merchant-info'>
              {merchant && (
                <>
                  <CoreProfileAvatar name={merchantName} />
                  {!collapsed && (
                    <div className='merchant-text-wrapper'>
                      <span className='merchant-title'>{merchantName}</span>
                      <span className='merchant-role'>
                        {customerRole && tr(`METADATA.USER_ROLE.${customerRole}`, customerRole?.toLowerCase())}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className='core-menu-settings-wrapper'>
              {!collapsed && (
                <>
                  {subAccounts?.length > 1 && (
                    <div>
                      <button className='sub-accounts-button' onClick={() => toggleDropDown()}>
                        {visible ? <DownArrowIcon /> : <UpArrowIcon />}
                      </button>
                      {visible && (
                        <div ref={setSubAccountRef} className='sub-account-wrapper'>
                          {isValidArray(filteredAccounts) ? (
                            <div className='sub-account-container'>
                              {filteredAccounts.map(account => {
                                const { name, merchantId } = account || {}
                                return (
                                  <div
                                    key={merchantId}
                                    className='sub-account-menu-item'
                                    onClick={() => {
                                      void handleSwitchMerchant(merchantId)
                                      toggleDropDown()
                                    }}>
                                    <CoreProfileAvatar name={name} />
                                    <span>{name}</span>
                                  </div>
                                )
                              })}
                            </div>
                          ) : (
                            <div
                              key={merchantId}
                              className='sub-account-menu-item'
                              onClick={() => {
                                void handleSwitchMerchant(merchantId)
                                toggleDropDown()
                              }}>
                              <span>Result not found</span>
                            </div>
                          )}
                          <div className='mt-1'>
                            <CoreInput
                              data-test='search'
                              type='text'
                              label='search'
                              value={terms}
                              size='small'
                              LeftIcon={<SearchIcon />}
                              onChange={(_, value) => {
                                handleChange(value)
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
              <CoreButton
                onClick={() => toggleDropDownSettings()}
                size='large'
                variation={collapsed ? 'tertiary' : 'elevated'}
                collapsed
                CollapsedIcon={<SettingsIcon />}
                className={cn({ 'menu-opened': visibleTemp })}
              />
              {visibleTemp && (
                <div ref={setSettingsRef} className='settings-menu'>
                  {isOwnerOrAdministrator() && (
                    <div
                      onClick={e => {
                        e.preventDefault()
                        history.push(paths.userManagement())
                        toggleDropDownSettings()
                      }}>
                      <UserIcon />
                      <span>User management</span>
                    </div>
                  )}

                  {isDevelopersMenuVisible() && (
                    <div
                      onClick={e => {
                        e.preventDefault()
                        history.push(paths.developer())
                        toggleDropDownSettings()
                      }}>
                      <DeveloperIcon />
                      <span>Developers</span>
                    </div>
                  )}
                  <div
                    onClick={e => {
                      e.preventDefault()
                      history.push(paths.profile())
                      toggleDropDownSettings()
                    }}>
                    <SettingsIcon />
                    <span>Profile settings</span>
                  </div>

                  <div onClick={handleLogoutClick} className='settings-menu-divider'>
                    <LogoutIcon />
                    <span>Log out</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
