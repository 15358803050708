import React, { useEffect, useMemo, useState } from 'react'

import ReactTooltip from 'react-tooltip'

import { ErrorDisplay } from 'mmfintech-portal-commons'

import { fixDateOnly, isValidArray, tr } from 'mmfintech-commons'
import {
  getExternalBankAccountFieldLabel,
  useCountries,
  useCurrencies,
  useEditBankAccountQry,
  useWithdrawAccountsQuery
} from 'mmfintech-backend-api'

import { CoreButton, CoreInput, CoreSelect, CoreSteps } from '@components'

import {
  AccountBalanceResponse,
  BankFieldMetadata,
  BankOut,
  CountrySupportedPurposeEnum,
  Currency,
  InstrumentFieldEnum,
  WithdrawalSubFlowEnum
} from 'mmfintech-commons-types'

import CalendarIcon from '@images/icons/filter-calendar.svg?react'

import '../styles/bankRecipientEdit.scss'

interface BankRecipientEditProps {
  accountId?: number
  supportedCountries?: string[]
  recipientId?: number
  currencyCode?: string
  onSuccess?: (response: any) => void
  counterpartyId?: string | number
  isEditableCurrency?: boolean
}

export const BankAccountRecipientEdit = (props: BankRecipientEditProps) => {
  const { accountId, supportedCountries, recipientId, currencyCode, onSuccess, counterpartyId, isEditableCurrency } =
    props

  const [selectedAccount, setSelectedAccount] = useState<AccountBalanceResponse>(null)

  const {
    data: validAccounts,
    isLoading: accountsLoading,
    isFetching: accountsFetching,
    error: accountsError
  } = useWithdrawAccountsQuery(WithdrawalSubFlowEnum.BANKWIRE, { skip: !!accountId })

  const { currencyOptions } = useCurrencies()
  const { countryOptions, countryOptionsFiltered } = useCountries(CountrySupportedPurposeEnum.REGISTRATION)

  const filteredCountries = useMemo(
    () =>
      countryOptionsFiltered(
        CountrySupportedPurposeEnum.WITHDRAWAL,
        accountId ? supportedCountries : selectedAccount?.supportedCountries
      ),
    [countryOptions, selectedAccount]
  )

  const {
    step,
    setStep,
    formValues,
    countryCode,
    setCountryCode,
    bankMetadata,
    bankMetadataError,
    bankMetadataFetching,
    accountTypeOptions,
    documentTypes,
    documentTypesOptions,
    handleSubmit,
    providedBanks,
    saveRecipientError,
    saveRecipientLoading
  } = useEditBankAccountQry({
    accountId,
    recipientId,
    currencyCode,
    enableProvidedBanks: true,

    onSuccess: (response: any) => {
      if (isEditableCurrency) {
        onSuccess({ ...response, currency: formValues.getValue('currency') })
      } else {
        onSuccess(response)
      }
    }
  })

  useEffect(() => {
    if (
      saveRecipientError &&
      (saveRecipientError as any)?.errorKey === 'MERCHANTS.ERROR.BANK_ACCOUNT_DATA_NOT_SUFFICIENT'
    ) {
      setStep(1)
    }
  }, [saveRecipientError])

  const metadataCustomError = useMemo(() => {
    if (bankMetadataError && formValues.getValue('currency') && countryCode) {
      return {
        error: `${tr(
          'FRONTEND.RECIPIENTS.METADATA.CUSTOM_ERROR_WITHDRAW',
          'Cannot withdraw {{CURRENCY}} in {{COUNTRY}}',
          {
            CURRENCY: formValues.getValue('currency'),
            COUNTRY: countryOptions?.find(x => x.value === countryCode)?.label
          }
        )}`
      }
    }
    return null
  }, [bankMetadataError])

  useEffect(() => {
    if (counterpartyId) {
      formValues.setValue('foreignId', counterpartyId)
    }
  }, [])

  // useEffect(() => {
  //   if (!recipientId && isValidArray(filteredCountries)) {
  //     if (!countryCode || !filteredCountries?.some(c => c.value === countryCode)) {
  //       setCountryCode(filteredCountries[0].value)
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [selectedAccount, filteredCountries])

  useEffect(() => {
    if (isValidArray(documentTypes)) {
      const { nationalId } = documentTypes[0]
      formValues.setValue('documentType', nationalId)
    }
    // eslint-disable-next-line
  }, [documentTypes])

  useEffect(() => {
    setTimeout(() => ReactTooltip.rebuild(), 500)
    // eslint-disable-next-line
  }, [bankMetadata])

  const handleContinue = () => {
    if (formValues.areValid(1)) {
      setStep(2)
    }
  }

  const validMetadataCategory = (field: BankFieldMetadata, category: string): boolean =>
    field.code !== InstrumentFieldEnum.COUNTRY_CODE &&
    field.code !== InstrumentFieldEnum.HOLDER_NAME &&
    field.fieldCategory === category

  const isSecondStep = bankMetadata?.filter(meta => validMetadataCategory(meta, 'RECIPIENT_DETAILS'))?.length > 0

  const internalSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    void handleSubmit()
  }

  return (
    <div className='recipient-edit-wrapper' data-test='bank-recipient-edit-modal'>
      <div className='subtitle'>
        {tr(
          'FRONTEND.RECIPIENTS.EDIT.SUBTITLE',
          'Please note that in order to proceed with the payment execution additional information about the recipient is required.'
        )}
      </div>
      {isSecondStep && (
        <div className='recipient-edit-steps'>
          <CoreSteps
            maxStep={2}
            step={step}
            title={
              step === 1
                ? tr('FRONTEND.RECIPIENTS.EDIT.INFO.PAYMENT', 'Payment information')
                : tr('FRONTEND.RECIPIENTS.EDIT.INFO.PERSONAL', 'Personal information')
            }
          />
        </div>
      )}

      <form className='styled-form' noValidate onSubmit={internalSubmit}>
        <div className='form-elements-container'>
          {step === 1 ? (
            <>
              {!accountId && (
                <CoreSelect
                  key='account'
                  type='account'
                  data-test='currency'
                  label={tr('FRONTEND.ACCOUNTS.CREATE.ACCOUNT_LABEL', 'Account')}
                  options={validAccounts}
                  isAccountsLoading={accountsLoading || accountsFetching}
                  onChange={(_, value) => {
                    formValues.setValue('currency', value?.currencyCode)
                    setSelectedAccount(value)
                  }}
                />
              )}
              {(!currencyCode || isEditableCurrency) && !!accountId && (
                <CoreSelect
                  type='default'
                  data-test='currency'
                  label={tr('FRONTEND.ACCOUNTS.CREATE.CURRENCY_LABEL', 'Currency')}
                  options={currencyOptions((c: Currency) => !!c.supportedForWithdrawal)}
                  {...formValues.registerInput('currency')}
                />
              )}
              <CoreSelect
                type='country'
                name='country'
                key='country'
                value={countryCode}
                options={filteredCountries}
                data-test='country-code'
                label={tr('FRONTEND.RECIPIENTS.LABELS.BANK_COUNTRY', 'Country')}
                onChange={(_: string, value: string) => setCountryCode(value)}
                required
              />
              <CoreInput
                type='text'
                data-test='account-name'
                maxLength={100}
                label={tr('FRONTEND.RECIPIENTS.LABELS.ACCOUNT_NAME', 'Recipient name')}
                tooltip={tr(formValues.getTooltip('name'), '')}
                {...formValues.registerInput('name')}
                autoComplete='off'
              />
              {isValidArray(bankMetadata) &&
                formValues &&
                bankMetadata
                  .filter(meta => validMetadataCategory(meta, 'BANK_DETAILS'))
                  .map((field, index) => (
                    <RenderField
                      key={field.code + index}
                      field={field}
                      formValues={formValues}
                      countryCode={countryCode}
                      accountTypes={accountTypeOptions}
                      providedBanks={providedBanks}
                      countryOptions={filteredCountries}
                      documentTypesOptions={documentTypesOptions}
                    />
                  ))}
              <CoreInput
                type='text'
                maxLength={100}
                data-test='description'
                label={tr('FRONTEND.RECIPIENTS.LABELS.DESCRIPTION', 'Description')}
                {...formValues.registerInput('description')}
                autoComplete='off'
              />
            </>
          ) : (
            <>
              {isValidArray(bankMetadata) &&
                bankMetadata
                  .filter(meta => validMetadataCategory(meta, 'RECIPIENT_DETAILS'))
                  .map((field, index) => (
                    <RenderField
                      key={field.code + index}
                      field={field}
                      formValues={formValues}
                      countryCode={countryCode}
                      accountTypes={accountTypeOptions}
                      providedBanks={providedBanks}
                      countryOptions={countryOptions}
                      documentTypesOptions={documentTypesOptions}
                    />
                  ))}
            </>
          )}
        </div>

        <ErrorDisplay error={[saveRecipientError, metadataCustomError, accountsError]} />

        <div className='recipient-edit-actions account'>
          {((isSecondStep && step === 2) || !isSecondStep) && (
            <div className='buttons'>
              {isSecondStep && step != 1 && (
                <CoreButton
                  variation='tertiary'
                  type='button'
                  text={tr('FRONTEND.BUTTONS.BACK', 'Back')}
                  data-test='button-back'
                  disabled={bankMetadataFetching}
                  onClick={() => setStep(step - 1)}
                  size='large'
                />
              )}

              <CoreButton
                type='submit'
                className='primary'
                text={tr('FRONTEND.RECIPIENTS.EDIT.BUTTON_SAVE', 'Save')}
                isLoading={saveRecipientLoading}
                disabled={bankMetadataFetching || !!metadataCustomError}
                data-test='button-submit'
                size='large'
                fullWidth
              />
            </div>
          )}

          {isSecondStep && step === 1 && (
            <CoreButton
              type='button'
              text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
              data-test='button-continue'
              disabled={bankMetadataFetching}
              onClick={handleContinue}
              fullWidth
              size='large'
            />
          )}
        </div>
      </form>

      <ReactTooltip id='info-tooltip' type='dark' effect='solid' place='top' multiline={true} />
    </div>
  )
}

const RenderField = ({
  field,
  formValues,
  countryCode,
  accountTypes,
  providedBanks,
  countryOptions,
  documentTypesOptions
}) => {
  const { code, fieldName } = field

  const providedBanksOptions = () =>
    isValidArray(providedBanks)
      ? providedBanks
          .filter((v: BankOut) => !!v.code?.length)
          .map((bank: BankOut) => ({ value: bank.id, label: bank.name }))
      : []

  const handleBankChange = (bankId: string): void => {
    const found = providedBanks?.find((bank: BankOut) => bank.id === Number(bankId))
    if (found) {
      formValues.setValue('bankCode', found.code)
      formValues.setValue('bankName', found.name)
    }
  }

  const findBankByName = (bankName: string) => {
    const found = providedBanks?.find((bank: BankOut) => bank.name === bankName)
    if (found) {
      formValues.setValue('bankCode', found.code)
      formValues.setValue('bankName', found.name)
    }
  }

  useEffect(() => {
    if (!formValues.getValue('bankCode') && providedBanks) {
      findBankByName(formValues.getValue('bankName'))
    }
  }, [formValues.getValue('bankName'), formValues.getValue('bankCode'), providedBanks])

  switch (code) {
    case InstrumentFieldEnum.BANK_CODE:
      return isValidArray(providedBanks) ? null : (
        <CoreInput
          type='text'
          data-test={code}
          label={getExternalBankAccountFieldLabel(countryCode, code)}
          tooltip={tr(formValues.getTooltip(fieldName), '')}
          {...formValues.registerInput(fieldName)}
        />
      )

    case InstrumentFieldEnum.BANK_NAME:
      if (isValidArray(providedBanks)) {
        return (
          <>
            <CoreSelect
              type='default'
              options={providedBanksOptions()}
              label={tr('FRONTEND.RECIPIENTS.LABELS.BANK_NAME', 'Bank Name')}
              placeholder={tr('FRONTEND.RECIPIENTS.LABELS.BANK_NAME', 'Bank Name')}
              {...formValues.registerInput('bankName', handleBankChange)}
              error={formValues.getError('bankName')}
            />
            <CoreInput
              type='text'
              readOnly={true}
              value={formValues.getValue('bankCode')}
              label={tr(`FRONTEND.RECIPIENTS.LABELS.BANK_CODE`, 'Bank code')}
              data-test='bank-code'
              {...formValues.registerInput('bankCode')}
            />
          </>
        )
      }

      return (
        <CoreInput
          type='text'
          data-test={code}
          label={getExternalBankAccountFieldLabel(countryCode, code)}
          tooltip={tr(formValues.getTooltip(fieldName), '')}
          {...formValues.registerInput(fieldName)}
        />
      )

    case InstrumentFieldEnum.DATE_OF_BIRTH:
      return (
        <CoreInput
          type='date'
          data-test={code}
          label={getExternalBankAccountFieldLabel(countryCode, code)}
          tooltip={tr(formValues.getTooltip(fieldName), '')}
          {...formValues.registerInput(fieldName)}
          dateFormat='MM/dd/yyyy'
          onChange={(name: string, val: any) => {
            const formattedString = fixDateOnly(val)
            formValues.setValue(name, formattedString)
          }}
          LeftIcon={<CalendarIcon />}
        />
      )

    case InstrumentFieldEnum.HOLDER_COUNTRY:
      return (
        <CoreSelect
          type='country'
          data-test={code}
          options={countryOptions}
          label={getExternalBankAccountFieldLabel(countryCode, code)}
          tooltip={tr(formValues.getTooltip(fieldName), '')}
          {...formValues.registerInput(fieldName)}
        />
      )

    case InstrumentFieldEnum.NATIONAL_ID_TYPE:
      return (
        <CoreSelect
          type='default'
          data-test={code}
          options={documentTypesOptions}
          label={getExternalBankAccountFieldLabel(countryCode, code)}
          tooltip={tr(formValues.getTooltip(fieldName), '')}
          {...formValues.registerInput(fieldName)}
        />
      )

    case InstrumentFieldEnum.ACCOUNT_TYPE:
      return (
        <CoreSelect
          type='default'
          options={accountTypes()}
          label={getExternalBankAccountFieldLabel(countryCode, code)}
          data-test={code}
          tooltip={tr(formValues.getTooltip(fieldName), '')}
          {...formValues.registerInput(fieldName)}
        />
      )

    case InstrumentFieldEnum.PHONE:
      return (
        <CoreInput
          key={code}
          type='phone'
          data-test={code}
          label={getExternalBankAccountFieldLabel(countryCode, code)}
          tooltip={tr(formValues.getTooltip(fieldName), '')}
          {...formValues.registerInput(fieldName)}
        />
      )

    case InstrumentFieldEnum.ENTITY_TYPE:
      return (
        <CoreSelect
          type='default'
          key={code}
          isSearchable={false}
          data-test={code}
          preselectedValue={{
            value: 'COMPANY',
            label: tr('FRONTEND.SIGNUP.ACCOUNT_TYPES.COMPANY', 'Corporate Account')
          }}
          options={[
            {
              value: 'INDIVIDUAL',
              label: tr('FRONTEND.SIGNUP.ACCOUNT_TYPES.INDIVIDUAL', 'Individual Account')
            },
            {
              value: 'COMPANY',
              label: tr('FRONTEND.SIGNUP.ACCOUNT_TYPES.COMPANY', 'Corporate Account')
            }
          ]}
          label={getExternalBankAccountFieldLabel(countryCode, code)}
          tooltip={tr(formValues.getTooltip(fieldName), '')}
          {...formValues.registerInput(fieldName)}
        />
      )

    default:
      return (
        <CoreInput
          id={fieldName}
          key={fieldName}
          type='text'
          data-test={code}
          label={getExternalBankAccountFieldLabel(countryCode, code)}
          tooltip={tr(formValues.getTooltip(fieldName), '')}
          {...formValues.registerInput(fieldName)}
          autoComplete='off'
        />
      )
  }
}
