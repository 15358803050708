import { useEffect } from 'react'

import { useCountries, useEditExternalWalletQry } from 'mmfintech-backend-api'
import { CountrySupportedPurposeEnum, ExternalWalletTypeEnum } from 'mmfintech-commons-types'
import { isValidArray, tr } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { CoreButton, CoreInput, CoreSelect } from '@components'

interface WalletRecipientEditProps {
  walletType: ExternalWalletTypeEnum
  recipientId?: number
  currencyCode?: string
  supportedCountries?: any[]
  onClose?: () => void
  onSuccess?: (response: any) => void
}

export const WalletRecipientEdit = (props: WalletRecipientEditProps) => {
  const { walletType, recipientId, currencyCode, supportedCountries, onClose, onSuccess } = props

  const { formValues, handleSubmit, error, fetching, resetMutations } = useEditExternalWalletQry({
    type: walletType,
    recipientId,
    currencyCode,
    onSuccess,
    onClose
  })

  const { countryOptions } = useCountries(CountrySupportedPurposeEnum.WITHDRAWAL)

  const getCountryOptions = () =>
    isValidArray(supportedCountries) && !supportedCountries.includes('ALL')
      ? countryOptions?.filter(node => supportedCountries.includes(node.value))
      : countryOptions

  const handleSubmitInternal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit()
  }

  useEffect(() => {
    return () => {
      resetMutations()
    }
  }, [])

  useEffect(() => {
    const options = getCountryOptions()
    if (isValidArray(options)) {
      const code = formValues.getValue('countryCode')
      if (!options.find(c => c.value === code)) {
        formValues.setValue('countryCode', options[0].value)
      }
    }
    // eslint-disable-next-line
  }, [countryOptions])

  return (
    <div className='recipient-edit-wrapper' data-test='wallet-recipient-edit-modal'>
      <div className='recipient-edit-title'>
        {tr('FRONTEND.RECIPIENTS.LABELS.WALLET_INFORMATION', 'Payment information')}
      </div>
      <form className='recipient-edit-form' noValidate onSubmit={handleSubmitInternal}>
        <CoreInput
          type='text'
          data-test='account-name'
          label={tr('FRONTEND.RECIPIENTS.JETON.NAME', 'Name')}
          {...formValues.registerInput('name')}
          autoComplete='off'
        />

        <CoreSelect
          type='country'
          data-test='address'
          options={countryOptions}
          label={tr('FRONTEND.RECIPIENTS.JETON.COUNTRY', 'Country')}
          {...formValues.registerInput('countryCode')}
        />

        <CoreInput
          type='text'
          data-test='identifier'
          label={tr('FRONTEND.RECIPIENTS.JETON.IDENTIFIER', 'Identifier')}
          {...formValues.registerInput('identifier')}
          autoComplete='off'
        />

        <CoreInput
          type='email'
          data-test='email'
          label={tr('FRONTEND.RECIPIENTS.JETON.EMAIL', 'Email')}
          {...formValues.registerInput('email')}
          autoComplete='off'
        />

        <CoreInput
          type='text'
          data-test='firstName'
          label={tr('FRONTEND.RECIPIENTS.JETON.FIRST_NAME', 'First name')}
          {...formValues.registerInput('firstName')}
          autoComplete='off'
        />

        <CoreInput
          type='text'
          data-test='lastName'
          label={tr('FRONTEND.RECIPIENTS.JETON.LAST_NAME', 'Last name')}
          {...formValues.registerInput('lastName')}
          autoComplete='off'
        />

        <CoreInput
          type='text'
          data-test='description'
          label={tr('FRONTEND.RECIPIENTS.JETON.DESCRIPTION', 'Description')}
          {...formValues.registerInput('description')}
          autoComplete='off'
        />

        <ErrorDisplay error={error} />

        <div className='recipient-edit-actions'>
          <CoreButton
            data-test='save-button'
            variation='primary'
            size='large'
            text={tr('FRONTEND.RECIPIENTS.BUTTON.SAVE', 'Save')}
            className='save-button'
            onClick={async e => {
              e.preventDefault()
              handleSubmit()
            }}
            isLoading={fetching}
            disabled={fetching}
            fullWidth
          />
        </div>
      </form>
    </div>
  )
}

/*

    <Input
        type='text'
        data-test='description'
        maxLength={100}
        label={tr('FRONTEND.RECIPIENTS.JETON.DESCRIPTION', 'Description')}
        {...formValues.registerInput('description')}
        autoComplete='off'
    />
*/
