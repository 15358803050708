import { MenuItem } from '@components'
import { paths } from 'mmfintech-backend-api'
import { CustomerRoleEnum, /* MerchantAccountTypeEnum */ Merchant } from 'mmfintech-commons-types'
import HomeIcon from '@images/icons/home-icon.svg'
import CardsIcon from '@images/icons/card_icon.svg'
import WalletIcon from '@images/icons/wallet-icon.svg'
import TransactionsIcon from '@images/icons/transactions-icon.svg'
import UserIcon from '@images/icons/user-icon.svg'
import InvoicesIcon from '@images/icons/invoices-icon.svg'
import SavingIcon from '@images/icons/saving-icon.svg'
import KingdomCashIcon from '@images/icons/kingodm-cash-icon.svg'
import InviteAndEarnIcon from '@images/icons/Invite-and-earn-icon.svg'
import ExpensesIcon from '@images/icons/expenses-icon.svg'
import RequestIbanIcon from '@images/icons/request-iban-icon.svg'
import ForexIcon from '@images/icons/forex-nav-icon.svg'

import HomeSvgIcon from '@images/icons/home-icon.svg?react'
import CardsSvgIcon from '@images/icons/card_icon.svg?react'
import WalletSvgIcon from '@images/icons/wallet-icon.svg?react'
import TransactionsSvgIcon from '@images/icons/transactions-icon.svg?react'
import UserSvgIcon from '@images/icons/user-icon.svg?react'
import InvoicesSvgIcon from '@images/icons/invoices-icon.svg?react'
import SavingSvgIcon from '@images/icons/saving-icon.svg?react'
import KingdomCashSvgIcon from '@images/icons/kingodm-cash-icon.svg?react'
import InviteAndEarnSvgIcon from '@images/icons/Invite-and-earn-icon.svg?react'
import ExpensesSvgIcon from '@images/icons/expenses-icon.svg?react'
import RequestIbanSvgIcon from '@images/icons/request-iban-icon.svg?react'
import ForexSvgIcon from '@images/icons/forex-nav-icon.svg?react'

export const coreMenuItems = (
  customerRole = CustomerRoleEnum.OWNER,
  isCardIssuingEnabled: boolean,
  merchant: Merchant
): MenuItem[] => {
  const { capabilities /*accountType*/ } = merchant || {}
  switch (customerRole) {
    case CustomerRoleEnum.STAFF:
      return [
        {
          imageSrc: HomeIcon,
          Image: HomeSvgIcon,
          label: 'Overview',
          path: paths.dashboard(),
          localization: 'FRONTEND.NAVIGATION.DASHBOARD',
          toolTip: 'Overview'
        },
        {
          imageSrc: WalletIcon,
          Image: WalletSvgIcon,
          label: 'Accounts',
          path: paths.banking.accounts.list(),
          localization: 'FRONTEND.NAVIGATION.ACCOUNTS',
          toolTip: 'Accounts'
        },
        ...(capabilities?.enableCardIssuing || isCardIssuingEnabled
          ? [
              {
                imageSrc: CardsIcon,
                Image: CardsSvgIcon,
                label: 'Cards',
                path: paths.cards.list(),
                localization: 'FRONTEND.NAVIGATION.CARDS',
                toolTip: 'Cards'
              }
            ]
          : []),
        {
          imageSrc: TransactionsIcon,
          Image: TransactionsSvgIcon,
          label: 'Transactions',
          path: paths.banking.transactions.list(),
          localization: 'FRONTEND.NAVIGATION.TRANSACTIONS',
          toolTip: 'Transactions'
        },
        {
          imageSrc: InvoicesIcon,
          Image: InvoicesSvgIcon,
          label: 'Invoices',
          path: paths.invoicesCustomersWrapper(),
          localization: 'FRONTEND.NAVIGATION.INVOICES',
          toolTip: 'Invoices'
        }
      ]

    case CustomerRoleEnum.SUBMITTER:
    case CustomerRoleEnum.APPROVER:
      return [
        {
          imageSrc: ExpensesIcon,
          Image: ExpensesSvgIcon,
          label: 'Expenses',
          path: paths.expenseManagement.expenseManagementDashboard(),
          localization: 'FRONTEND.NAVIGATION.EXPENSES',
          toolTip: 'Expenses'
        },
        ...(capabilities?.enableCardIssuing || isCardIssuingEnabled
          ? [
              {
                imageSrc: CardsIcon,
                Image: CardsSvgIcon,
                label: 'Cards',
                path: paths.cards.list(),
                localization: 'FRONTEND.NAVIGATION.CARDS',
                toolTip: 'Cards'
              }
            ]
          : [])
      ]

    default:
      return [
        {
          imageSrc: HomeIcon,
          Image: HomeSvgIcon,
          label: 'Overview',
          path: paths.dashboard(),
          localization: 'FRONTEND.NAVIGATION.DASHBOARD',
          toolTip: 'Overview'
        },
        {
          imageSrc: WalletIcon,
          Image: WalletSvgIcon,
          label: 'Accounts',
          path: paths.banking.accounts.list(),
          localization: 'FRONTEND.NAVIGATION.ACCOUNTS',
          toolTip: 'Accounts'
        },
        // ...(capabilities?.enableCardIssuing && accountType == MerchantAccountTypeEnum.INDIVIDUAL
        ...(capabilities?.enableCardIssuing || isCardIssuingEnabled
          ? [
              {
                imageSrc: CardsIcon,
                Image: CardsSvgIcon,
                label: 'Cards',
                path: paths.cards.list(),
                localization: 'FRONTEND.NAVIGATION.CARDS',
                toolTip: 'Cards'
              }
            ]
          : []),
        {
          imageSrc: TransactionsIcon,
          Image: TransactionsSvgIcon,
          label: 'Transactions',
          path: paths.banking.transactions.list(),
          localization: 'FRONTEND.NAVIGATION.TRANSACTIONS',
          toolTip: 'Transactions'
        },
        {
          imageSrc: UserIcon,
          Image: UserSvgIcon,
          label: 'Recipients',
          path: paths.recipients.list(),
          localization: 'FRONTEND.NAVIGATION.RECIPIENTS',
          toolTip: 'Recipients'
        },
        {
          imageSrc: InvoicesIcon,
          Image: InvoicesSvgIcon,
          label: 'Invoices',
          path: paths.invoicesCustomersWrapper(),
          localization: 'FRONTEND.NAVIGATION.INVOICES',
          toolTip: 'Invoices'
        },
        {
          imageSrc: SavingIcon,
          Image: SavingSvgIcon,
          label: 'Savings',
          path: paths.more.savings(),
          localization: 'FRONTEND.NAVIGATION.SAVINGS',
          toolTip: 'Savings'
        },
        {
          imageSrc: KingdomCashIcon,
          Image: KingdomCashSvgIcon,
          label: 'Kingdom Cash',
          path: paths.more.cashVoucher('buy'),
          localization: 'FRONTEND.NAVIGATION.KINGDOM_CASH',
          toolTip: 'Kingdom Cash'
        },
        {
          imageSrc: InviteAndEarnIcon,
          Image: InviteAndEarnSvgIcon,
          label: 'Invite & Earn',
          path: paths.referralProgram(),
          localization: 'FRONTEND.NAVIGATION.INVITE_AND_EARN',
          toolTip: 'Invite & Earn'
        },
        {
          imageSrc: ForexIcon,
          Image: ForexSvgIcon,
          label: 'Forex',
          path: paths.forex(),
          localization: 'FRONTEND.HEADER.FOREX',
          toolTip: 'Forex'
        },
        {
          imageSrc: ExpensesIcon,
          Image: ExpensesSvgIcon,
          label: 'Expenses',
          path: paths.expenseManagement.expenseManagementDashboard(),
          localization: 'FRONTEND.NAVIGATION.EXPENSES',
          toolTip: 'Expenses'
        },
        {
          imageSrc: RequestIbanIcon,
          Image: RequestIbanSvgIcon,
          label: 'Request IBAN',
          path: paths.ibanIssuing(),
          localization: 'FRONTEND.NAVIGATION.REQUEST_IBAN',
          toolTip: 'Request IBAN'
        }
      ]
  }
}
