import { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { configuration } from 'mmfintech-backend-api'
import { useMatchMedia } from '@hooks'
import { getPaymentMethodImageUrl, isValidArray, tr } from 'mmfintech-commons'

import { PaymentOptionResponse, PaymentOptionStatusEnum } from 'mmfintech-commons-types'

import './PaymentMethods.scss'

import BankTransferImage from '@images/methods/bank-transfer-icon.svg'
import JetonImage from '@images/methods/jeton-icon.svg'
import SwiftImage from '@images/methods/swift-icon.svg'
import CardImage from '@images/methods/debit-card-icon.svg'
import KingdomCashImage from '@images/methods/kingdom-cash-icon.svg'
import CryptoImage from '@images/methods/crypto-icon.svg'
import InternalTransferImage from '@images/methods/internal-transfer.svg'
import BankwireImage from '@images/methods/bankwire-icon.svg'
import BatchPaymentImage from '@images/icons/batch-payment-icon.svg'

type TMorePaymentOptions = {
  description?: string
  title?: string
  dataTest?: string
  path?: string
  isDisabled?: boolean
}
interface IPaymentMethodProps {
  paymentOptions: (PaymentOptionResponse & TMorePaymentOptions)[]
  setSelectedPaymentOption?: (value: string, path?: string) => void
  selectedPaymentOption?: PaymentOptionResponse
  collapse?: boolean
  loading?: boolean
  disabled?: boolean
  setCollapse?: Dispatch<SetStateAction<boolean>>
  ignoreStatus?: boolean
}

export const PaymentMethods = ({
  paymentOptions,
  setSelectedPaymentOption,
  selectedPaymentOption,
  collapse,
  // loading,
  disabled = false,
  setCollapse,
  ignoreStatus = false
}: IPaymentMethodProps) => {
  const enableCollapse = useMatchMedia({ breakpoint: 1280, prefix: 'max' }) // collapse state (breakpoint)
  const { paymentMethod, feePercent, feeFlat, feeFlatCurrency } = selectedPaymentOption || {}
  const [filteredOptions, setFilteredOptions] =
    useState<(PaymentOptionResponse & TMorePaymentOptions)[]>(paymentOptions)
  const paymentMethodsContainer = useRef(null)

  useEffect(() => {
    if (enableCollapse && isValidArray(paymentOptions) && paymentMethod && selectedPaymentOption) {
      setTimeout(() => {
        setFilteredOptions(paymentOptions.filter(option => option.paymentMethod !== paymentMethod))
      }, 300)
    } else {
      setFilteredOptions(paymentOptions)
    }
  }, [enableCollapse, selectedPaymentOption, paymentOptions])

  useEffect(() => {
    if (enableCollapse && !collapse && paymentMethodsContainer.current) {
      paymentMethodsContainer.current.scroll(0, 0)
    }
  }, [collapse, enableCollapse])

  const PaymentOptionIcon = (paymentMethod: string): ReactNode => {
    switch (paymentMethod) {
      case 'INSTANT_BANK_TRANSFER':
        return <img src={BankTransferImage} alt='' />
      case 'JETON_CASH':
        return <img src={JetonImage} alt='' />
      case 'BANKWIRE':
        return <img src={BankwireImage} alt='' />
      case 'SWIFT_BANKWIRE':
        return <img src={SwiftImage} alt='' />
      case 'CREDIT_CARD':
        return <img src={CardImage} alt='' />
      case 'KINGDOM_CASH':
      case 'INTERNAL_TRANSFER':
        return <img src={KingdomCashImage} alt='' />
      case 'CRYPTO':
        return <img src={CryptoImage} alt='' />
      case 'INTRA_ACCOUNT_TRANSFER':
        return <img src={InternalTransferImage} alt='' />
      case 'BATCH':
        return <img src={BatchPaymentImage} alt='' />

      // case 'CASH_TO_CODE':
      //   return <CashToCodeIcon />
      // case 'PERFECT_MONEY':
      //   return <PerfectMoneyIcon />
      // case 'PIX':
      //   return <PixIcon />
      // case 'KHIPU':
      //   return <KhipuIcon />
      // case 'SAFETYPAY_BANK':
      // case 'SAFETYPAY_CASH':
      //   return <SafetyPayIcon />
      // case 'INTERAC':
      //   return <InteracIcon />

      default:
        return <img src={`${configuration.getBaseUrl()}${getPaymentMethodImageUrl(paymentMethod)}`} alt='' />
    }
  }

  const hoverAttributes = () => {
    return {
      onMouseOver: event => {
        const target = event.target as HTMLSpanElement
        event.stopPropagation()
        if (target.clientWidth > target.parentElement.clientWidth || target.scrollWidth > target.clientWidth) {
          const translateX = Math.max((target.clientWidth - target.parentElement.clientWidth + 24), target.scrollWidth - target.clientWidth)
          target.style.transform = `translateX(${-translateX}px)`
        }
      },
      onMouseLeave: event => {
        const target = event.target as HTMLSpanElement
        target.style.transform = `translateX(0px)`
      }
    }
  }

  return (
    <div
      style={{
        height: enableCollapse && collapse ? `${8.8 * filteredOptions.length}rem` : '',
        overflow: enableCollapse && !collapse ? `hidden` : 'auto'
      }}
      className={cn('payment-methods-wrapper')}
      ref={paymentMethodsContainer}>
      {enableCollapse && selectedPaymentOption && (
        <div className={cn('payment-method-wrapper full-width selected')} onClick={() => setCollapse(true)}>
          <div className='payment-method-icon-wrapper'>{PaymentOptionIcon(paymentMethod)}</div>
          <div className='payment-method-content'>
            <div className='payment-method-title-wrapper'>
              <span {...hoverAttributes()}>{tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}</span>
            </div>
            <FeeView feePercent={feePercent} feeFlat={feeFlat} feeFlatCurrency={feeFlatCurrency} />
          </div>
        </div>
      )}
      {isValidArray(filteredOptions) &&
        filteredOptions.map(method => {
          const {
            paymentMethod,
            feePercent,
            description,
            title,
            dataTest,
            path,
            isDisabled,
            status,
            feeFlat,
            feeFlatCurrency
          } = method || {}
          return (
            <div
              key={paymentMethod}
              onClick={() => setSelectedPaymentOption(paymentMethod, path)}
              date-test={dataTest}
              className={cn('payment-method-wrapper', {
                'full-width': filteredOptions.length === 1,
                selected:
                  !enableCollapse && selectedPaymentOption && selectedPaymentOption.paymentMethod === paymentMethod,
                disabled: disabled || isDisabled || (!ignoreStatus && status !== PaymentOptionStatusEnum.AVAILABLE)
              })}>
              <div className='payment-method-icon-wrapper'>{PaymentOptionIcon(paymentMethod)}</div>
              <div className='payment-method-content'>
                <div className='payment-method-title-wrapper'>
                  <span {...hoverAttributes()}>
                    {title || tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)}
                  </span>
                </div>

                {(description || paymentMethodsDescriptions()[paymentMethod]) && <span className='subtitle' {...hoverAttributes()}>
                  {paymentMethodsDescriptions()[paymentMethod] || description}
                </span>}
                <FeeView feePercent={feePercent} feeFlat={feeFlat} feeFlatCurrency={feeFlatCurrency} />
              </div>
            </div>
          )
        })}
    </div>
  )
}

const FeeView = ({
  feePercent,
  feeFlat,
  feeFlatCurrency
}: {
  feePercent: number
  feeFlat: number
  feeFlatCurrency: string
}) => {
  if (!feePercent && !feeFlat) return null

  return (
    <div className='fee-wrapper'>
      {feePercent && (
        <span className='subtitle'>{`${tr('FRONTEND.PAYMENT_OPTIONS.FEE_LABEL', 'Fee')} ${feePercent}%`}</span>
      )}
      {feePercent && feeFlat && <span className='subtitle'>|</span>}
      {feeFlat && (
        <span className='subtitle'>
          {`${tr('FRONTEND.PAYMENT_OPTIONS.FEE_LABEL', 'Flat fee')} ${feeFlat}`}{' '}
          <span className='fee-currency'>{feeFlatCurrency}</span>
        </span>
      )}
    </div>
  )
}

const paymentMethodsDescriptions = () => {
  return {
    'BINANCE_PAY': tr('FRONTEND.PAYMENT_OPTIONS.DESCRIPTION.BINANCE_PAY', 'Fast and secure deposits from your Binance account')
  }
}