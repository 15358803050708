import { useEffect, useMemo, useState } from 'react'

import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { MerchantAccountTypeEnum, MerchantEntityTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

type OnboardingStep = 0 | 1 | 2 | 3

export const useOnboardingStep = () => {
  const { data: merchant } = useGetMerchantQuery()
  const { accountType, onboardingStatus, entityType } = merchant || {}
  const [currentOnboardingStep, setCurrentOnboardingStep] = useState<OnboardingStep>(0)

  const isIndividual = useMemo(() => entityType === MerchantEntityTypeEnum.INDIVIDUAL, [entityType])
  const totalSteps = useMemo(() => (isIndividual ? 2 : 3), [isIndividual])

  useEffect(() => {
    if (accountType === MerchantAccountTypeEnum.PROSPECT) {
      switch (onboardingStatus) {
        case OnboardingStatusEnum.NOT_STARTED:
          setCurrentOnboardingStep(0)
          break
        case OnboardingStatusEnum.WAITING_QUESTIONNAIRE:
          setCurrentOnboardingStep(1)
          break
        case OnboardingStatusEnum.IN_PROGRESS:
        case OnboardingStatusEnum.UNDER_REVIEW:
        case OnboardingStatusEnum.REJECTED_RETRY:
        case OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL:
        case OnboardingStatusEnum.WAITING_OPS_APPROVAL:
        case OnboardingStatusEnum.WAITING_ADDITIONAL_DOCUMENTS:
        case OnboardingStatusEnum.WAITING_BUSINESS_AGREEMENT_APPROVAL:
          setCurrentOnboardingStep((totalSteps - 1) as OnboardingStep)
          break
        case OnboardingStatusEnum.APPROVED:
          setCurrentOnboardingStep(totalSteps)
          break
      }
    } else {
      setCurrentOnboardingStep(totalSteps)
    }
  }, [onboardingStatus])

  return {
    currentOnboardingStep,
    totalSteps
  }
}
