// noinspection CssUnusedSymbol,DuplicatedCode

import { useState, useEffect, Suspense, useContext } from 'react'
import { Route, Switch, useHistory, matchPath } from 'react-router-dom'

import styled, { ThemeProvider } from 'styled-components'
import { ErrorBoundary } from 'react-error-boundary'
import { CookiesProvider, useCookies } from 'react-cookie'
import pathToRegexp from 'path-to-regexp'

import { GlobalContextProvider, OtpContext, isValidObject, isValidString } from 'mmfintech-commons'
import {
  selectCurrentUserRole,
  selectCurrentUserStatus,
  useAppInitializerQry,
  useAppSelector,
  useAuthQry,
  useCardAuth,
  useLazyErrorLoggingQuery
} from 'mmfintech-backend-api'

import {
  CookieConsent,
  CoreToaster,
  OtpNew,
  Header,
  PrivateRoute,
  PublicRoute,
  statusPaths,
  CoreModalDialog,
  CoreSidebarRight,
  CoreCoinLoader,
  prepareOtpTitle
} from '@components'

import theme from './theme'
import settings from '@settings'
import { withTranslation } from 'react-i18next'
import {
  adminRoutes,
  approverRoutes,
  logoutAnd404Routes,
  nonProtectedRoutes,
  staffRoutes,
  submitterRoutes
} from './routes'

import { CustomerRoleEnum, LoginStatusEnum } from 'mmfintech-commons-types'
import { ThemeContext } from './context/ThemeContext'
import Error404 from './views/auth/Error404'
import '@scss/app.scss'
import { CoreMenu } from '@components'
import HeaderContextProvider from './context/HeaderContext'

import ErrorIcon from '@images/icons/error.svg?react'

const pathExistsInProtectedRoutes = (path: string): boolean => {
  return [...nonProtectedRoutes, ...logoutAnd404Routes].some((route: any) => {
    const test = route.check
      ? isValidObject(matchPath(path, { path: route.check, exact: false, strict: false }))
      : false
    return route.path === path || test
  })
}

const AppInner = () => {
  useAppInitializerQry(settings.languages)

  const [cookies, setCookie] = useCookies(['cookie.consent'])
  const [cookieConsent, setCookieConsent] = useState(null)

  const aYearFromNow = new Date()
  aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)

  const history = useHistory()
  const { darkTheme } = useContext(ThemeContext)

  const { isAuthFetching, authSucceeded } = useAuthQry()
  const { fetchCardAuthToken } = useCardAuth()

  const customerRole = useAppSelector(selectCurrentUserRole)
  const userStatus = useAppSelector(selectCurrentUserStatus)

  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN

  const routes = () => {
    switch (customerRole) {
      case CustomerRoleEnum.SUBMITTER:
        return submitterRoutes
      case CustomerRoleEnum.APPROVER:
        return approverRoutes
      case CustomerRoleEnum.STAFF:
        return staffRoutes
      default:
        return adminRoutes
    }
  }

  const queryChallenge = useAppSelector(state => state.challenge)

  useEffect(() => {
    setCookieConsent(cookies['cookie.consent'])
  }, [cookies])

  const [otpOnError, setOtpOnError] = useState(null)
  const [otpOnSuccess, setOtpOnSuccess] = useState(null)

  // Global center modal state
  const [modalContent, setModalContent] = useState(null)
  const [modalHeader, setModalHeader] = useState(null)
  const [modalOptions, setModalOptions] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)

  // global right modal state
  const [sidebarRightContent, setSidebarRightContent] = useState(null)
  const [sidebarRightHeader, setSidebarRightHeader] = useState(null)
  const [sidebarRightOptions, setSidebarRightOptions] = useState(null)
  const [sidebarRightVisible, setSidebarRightVisible] = useState(false)

  const modalHide = () => {
    setModalVisible(false)
    setModalContent(null)
    setModalOptions(null)
    setModalHeader(null)
  }

  const modalShow = ({ options, content, header }) => {
    setModalVisible(true)
    setModalContent(content)
    setModalOptions(options)
    setModalHeader(header)
  }

  const sidebarRightHide = () => {
    setSidebarRightVisible(false)
    setSidebarRightContent(null)
    setSidebarRightOptions(null)
    setSidebarRightHeader(null)
  }

  const sidebarRightShow = ({ options, content, header }) => {
    setSidebarRightContent(content)
    setSidebarRightOptions(options)
    setSidebarRightVisible(true)
    setSidebarRightHeader(header)
  }

  const globalContext = { modalHide, modalShow, sidebarRightHide, sidebarRightShow }

  useEffect(() => {
    if (typeof queryChallenge === 'object' && queryChallenge.hasOwnProperty('challengeId')) {
      modalShow({
        header: prepareOtpTitle(queryChallenge, userStatus),
        options: {
          transparent: false,
          closeOnClickOutside: false
        },
        content: <OtpNew />
      })
    } else {
      modalVisible && modalHide()
    }
    // eslint-disable-next-line
  }, [queryChallenge])

  useEffect(() => {
    window.addEventListener('storage', e => {
      if (e.key === 'cookieTimestamp' && e.newValue !== e.oldValue) {
        history.go(0)
      }
    })
  }, [])

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', darkTheme ? 'dark' : 'light')
  }, [darkTheme])

  useEffect(() => {
    if (authSucceeded) {
      fetchCardAuthToken()
    }
  }, [authSucceeded])

  if (isAuthFetching && !pathExistsInProtectedRoutes(location?.pathname)) {
    return <PreloaderWrapper>{CoreCoinLoader && <CoreCoinLoader />}</PreloaderWrapper>
  }

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <GlobalContextProvider context={globalContext}>
          <OtpContext.Provider value={{ otpOnSuccess, setOtpOnSuccess, otpOnError, setOtpOnError }}>
            <HeaderContextProvider>
              <div className='app-container'>
                {isLoggedIn() &&
                  !nonProtectedRoutes.find(route => pathToRegexp(route.path).test(location.pathname)) && <CoreMenu />}
                <div className='main-content'>
                  {isLoggedIn() &&
                    !nonProtectedRoutes.find(route => pathToRegexp(route.path).test(location.pathname)) && (
                      <div className='header'>
                        <Header />
                      </div>
                    )}
                  <div className='content-container'>
                    <Switch>
                      {nonProtectedRoutes.map(({ path, component, redirect }: any, index) => {
                        if (redirect) {
                          return (
                            <PrivateRoute
                              key={index}
                              path={path}
                              exact
                              component={component}
                              redirect={redirect}
                              invalidSessionRedirect={statusPaths(userStatus)}
                            />
                          )
                        }
                        return <PublicRoute key={index} path={path} exact component={component} />
                      })}
                      {routes().map(({ path, component: PrivateComponent, redirect }: any, index: number) => {
                        return (
                          <PrivateRoute
                            key={index}
                            path={path}
                            exact
                            component={PrivateComponent}
                            redirect={redirect}
                            invalidSessionRedirect={statusPaths(userStatus)}
                          />
                        )
                      })}
                      <Route path='*' component={Error404} />
                    </Switch>
                    {isValidObject(cookieConsent) ? null : (
                      <CookieConsent setCookie={setCookie} cookie={cookies['cookie.consent']} />
                    )}
                  </div>
                  <CoreModalDialog
                    header={modalHeader}
                    content={modalContent}
                    options={modalOptions}
                    visible={modalVisible}
                    onClose={modalHide}
                  />
                  <CoreSidebarRight
                    header={sidebarRightHeader}
                    content={sidebarRightContent}
                    options={sidebarRightOptions}
                    visible={sidebarRightVisible}
                    onClose={sidebarRightHide}
                  />
                </div>
              </div>
              <CoreToaster />
            </HeaderContextProvider>
          </OtpContext.Provider>
        </GlobalContextProvider>
      </ThemeProvider>
    </CookiesProvider>
  )
}

function ErrorFallback({ error, resetErrorBoundary }) {
  const isDynamicModuleError = isValidString(error?.message)
    ? error.message.includes('Failed to fetch dynamically imported module')
    : false
  return (
    <AlertWrapper>
      <Alert>
        <ErrorIcon />
        <p>
          {isDynamicModuleError ? 'There might be a new version of the application' : 'An unexpected error occurred'}
        </p>
        <TryAgainButton type='button' onClick={() => resetErrorBoundary(isDynamicModuleError)}>
          {isDynamicModuleError ? 'Reload page' : 'Go back to the home page'}
        </TryAgainButton>
      </Alert>
    </AlertWrapper>
  )
}

// const CustomRoute = ({ component: Component, ...rest }: RouteProps) => (
//   <Route {...rest} render={props => <Component {...props} />} />
// )

const ThisApp = withTranslation()(AppInner)

const App = () => {
  const [logError] = useLazyErrorLoggingQuery()

  return (
    <Suspense fallback={<PreloaderWrapper>{CoreCoinLoader && <CoreCoinLoader />}</PreloaderWrapper>}>
      <ErrorBoundary
        onError={(err, componentStack) => {
          try {
            const reloadTimestamp = localStorage.getItem('dynamicImportTimestamp')
            if (
              err?.message?.includes('dynamically imported module') &&
              (!reloadTimestamp || Date.now() - Number(reloadTimestamp) > 60_000)
            ) {
              localStorage.setItem('dynamicImportTimestamp', Date.now().toString())
              return window.location.reload()
            }
          } catch (error) {
            // handles cases in which the browser has no access to the storage
            console.error(error)
          }
          return logError({
            level: 'ERROR',
            componentStack: componentStack?.componentStack || componentStack,
            message: err.toString() + ' | ' + window?.location?.pathname
          })
        }}
        onReset={isDynamicModuleError => {
          if (isDynamicModuleError) {
            window.location.reload()
          } else {
            window.location.replace('/')
          }
        }}
        FallbackComponent={ErrorFallback}>
        <ThisApp />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App

const TryAgainButton = styled.button`
  cursor: pointer;
  outline: none;
  width: 100%;
  padding: 1.5rem;

  color: #ffffff;
  background: #ff4c4d;
  box-shadow: 0 5px 17px rgba(255, 165, 159, 0.5);

  border-radius: 5px;
  border: none;
`

const PreloaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
`

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 8rem;
`

const Alert = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  max-width: 50rem;
  width: 100%;
  border-radius: 1rem;
  padding: 3rem;

  text-align: center;
  font-size: 16px;

  img {
    width: 66px;
    height: 38px;
  }
  .button {
    width: 100%;
    max-width: 30rem;
  }
`
