import React from 'react'

import { paths } from 'mmfintech-backend-api'

import Dashboard from '@views/dashboard'
import Outlet from '@views/Outlet'
import SendMoney from '@views/banking/SendMoney'

import CashVoucher from '@views/cashVoucher/CashVoucher'

const LazyAdsRegisterPage = React.lazy(() => import('@views/adsPages/AdsRegister'))
const LazyAdsRegisterPageMCA = React.lazy(() => import('@views/adsPages/AdsRegisterMCA'))
const LazyAdsRegisterOffshoreAndOverseas = React.lazy(() => import('@views/adsPages/AdsRegisterOffshoreAndOverseas'))

const LazyError404 = React.lazy(() => import('@views/auth/Error404'))
const LazySignup = React.lazy(() => import('@views/auth/Signup'))
const LazySumSubVerification = React.lazy(() => import('@views/onboarding/SumSubVerification'))
const LazyVerifyEmail = React.lazy(() => import('@views/auth/VerifyEmail'))

const ScanAndPay = React.lazy(() => import('@views/scanAndPay/ScanAndPay'))
const ScanAndPayP2P = React.lazy(() => import('@views/scanAndPay/PeerToPeer'))
const P2PQrCodePage = React.lazy(() => import('@views/scanAndPay/QRPayment'))

const LazyAccounts = React.lazy(() => import('@views/accounts/Accounts'))
const Transactions = React.lazy(() => import('@views/transactions/Transactions'))
const UserManagement = React.lazy(() => import('@views/users/UserManagement'))

const LazyDeposit = React.lazy(() => import('@views/banking/Deposit'))
const LazyExchange = React.lazy(() => import('@views/banking/Exchange'))

const Recipients = React.lazy(() => import('@views/recipients/Recipients'))
const RecipientsDetails = React.lazy(() => import('@views/recipients/RecipientDetailsPage'))

const Invoices = React.lazy(() => import('@views/invoices/Invoices'))
const InvoiceEdit = React.lazy(() => import('@views/invoices/InvoiceEdit/InvoiceEdit'))
const InvoiceSend = React.lazy(() => import('@views/invoices/InvoiceSend/InvoiceSend'))
const InvoiceUploadedEdit = React.lazy(() => import('@views/invoices/InvoiceUploadedEdit/InvoiceUploadedEdit'))
const InvoicesCustomerWrapper = React.lazy(
  () => import('@views/invoices/InvoicesCustomerWrapper/InvoicesCustomerWrapper')
)

/** Reports **/
import ExpenseDashboard from '@views/dashboard/ExpensesDashboard'
import ExpenseManagementWrapper from '@views/expenseManagement'

const LazyReportDetails = React.lazy(() => import('@views/expenseManagement/reportDetails'))
const PaymentsPage = React.lazy(() => import('@views/payments/PaymentsPage'))
const CounterpartyPage = React.lazy(() => import('@views/counterparty/CounterpartyPage/CounterpartyPage'))
const PayPage = React.lazy(() => import('@views/pay-expenses/PayPage'))
/** Reports **/

import ProfileSettings from '@views/settings/ProfileSettings/ProfileSettings'
import BatchPayment from '@views/banking/BatchPayment'
// import IbanRequestPreview from '@views/banking/new_RequestIBAN'
import IbanIssuing from '@views/banking/RequestIBAN'
// import IbanIssuingPreview from '@views/banking/new_RequestIBANPreview'

// prettier-ignore
export const nonProtectedRoutes = [
  { path: paths.login(), component: React.lazy(() => import('@views/auth/Login')) },
  { path: paths.signUpViaInviteLink(':referralCode'), check: paths.signUpViaInviteLink('*'), component: LazySignup },
  { path: paths.signUp(), component: LazySignup },
  { path: paths.invite(':link'), check: paths.invite('*'), component: React.lazy(() => import('@views/auth/InviteHandler')) },
  { path: paths.forgotPassword(), component: React.lazy(() => import('@views/auth/ForgotPassword')) },
  { path: paths.changePasswordEmail(), component: React.lazy(() => import('@views/auth/ForgotPasswordMessage')) },
  { path: paths.passwordReset(), component: React.lazy(() => import('@views/auth/ResetPasswordHandler')) },
  { path: paths.handlePasswordReset(), component: React.lazy(() => import('@views/auth/ResetPassword')) },
  { path: paths.checkEmail(), component: React.lazy(() => import('@views/auth/VerifyEmailMessage')) },
  { path: paths.handleEmailVerify(), component: LazyVerifyEmail },
  { path: paths.handleInvitationVerify(), component: LazyVerifyEmail },
  { path: paths.handlePasswordResetInvited(), component: React.lazy(() => import('@views/auth/SetPassword')) },
  { path: paths.sendMoneyP2P(':publicMerchantId([a-zA-Z0-9]{10,})'), component: ScanAndPayP2P },

  { path: paths.invoices.checkout(':magicToken'), check: paths.invoices.checkout('*'), component: React.lazy(() => import('@views/invoices/InvoiceCheckout')) },
  { path: paths.invoices.viewByToken(':magicToken'), check: paths.invoices.viewByToken('*'), component: React.lazy(() => import('@views/invoices/InvoicePreview')) },

  { path: paths.expos.webX24, component: LazySignup },
  { path: paths.expos.sigmaEE24, component: LazySignup },
  { path: paths.expos.entertainmentArena24, component: LazySignup },
  { path: paths.expos.worldFinanceForum24, component: LazySignup },
  { path: paths.expos.tes24PRG, component: LazySignup },
  { path: paths.expos.cyprusGaming24, component: LazySignup },
  { path: paths.expos.singaporeFintech24, component: LazySignup },
  { path: paths.expos.worldBlockchain24, component: LazySignup },
  { path: paths.expos.sigmaEurope24, component: LazySignup },
  { path: paths.expos.financeMagnets24, component: LazySignup },
  { path: paths.expos.globalBankingFT24, component: LazySignup },

  { path: paths.ads.b2bPayment_FC, component: LazyAdsRegisterPage },
  { path: paths.ads.b2bPayment_MT, component: LazyAdsRegisterPage },
  { path: paths.ads.b2bPayment_RD, component: LazyAdsRegisterPage },
  { path: paths.ads.b2bPayment_TC, component: LazyAdsRegisterPage },
  { path: paths.ads.b2bPayment_TW, component: LazyAdsRegisterPage },

  { path: paths.ads.bestOffshoreBankAccount, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.bestOffshoreBankAccount_A, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.bestOffshoreBankAccount_B, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.bestOffshoreBankAccount_C, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.bestOffshoreBankAccount_D, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.bestOffshoreBankAccount_E, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.bestOffshoreBankAccount_FC, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.bestOffshoreBankAccount_MT, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.bestOffshoreBankAccount_PX, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.bestOffshoreBankAccount_RD, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.bestOffshoreBankAccount_TC, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.bestOffshoreBankAccount_TCC, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.bestOffshoreBankAccount_TW, component: LazyAdsRegisterOffshoreAndOverseas },

  { path: paths.ads.businessAccount, component: LazyAdsRegisterPage },
  { path: paths.ads.businessAccount_A, component: LazyAdsRegisterPage },
  { path: paths.ads.businessAccount_B, component: LazyAdsRegisterPage },
  { path: paths.ads.businessAccount_C, component: LazyAdsRegisterPage },
  { path: paths.ads.businessAccount_D, component: LazyAdsRegisterPage },
  { path: paths.ads.businessAccount_E, component: LazyAdsRegisterPage },
  { path: paths.ads.businessAccount_FC, component: LazyAdsRegisterPage },
  { path: paths.ads.businessAccount_MT, component: LazyAdsRegisterPage },
  { path: paths.ads.businessAccount_PX, component: LazyAdsRegisterPage },
  { path: paths.ads.businessAccount_RD, component: LazyAdsRegisterPage },
  { path: paths.ads.businessAccount_TC, component: LazyAdsRegisterPage },
  { path: paths.ads.businessAccount_TCC, component: LazyAdsRegisterPage },
  { path: paths.ads.businessAccount_TW, component: LazyAdsRegisterPage },

  { path: paths.ads.cryptoFriendlyBank_FC, component: LazyAdsRegisterPage },
  { path: paths.ads.cryptoFriendlyBank_MT, component: LazyAdsRegisterPage },
  { path: paths.ads.cryptoFriendlyBank_RD, component: LazyAdsRegisterPage },
  { path: paths.ads.cryptoFriendlyBank_TC, component: LazyAdsRegisterPage },
  { path: paths.ads.cryptoFriendlyBank_TW, component: LazyAdsRegisterPage },

  { path: paths.ads.internationalBankAccount_FC, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.internationalBankAccount_MT, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.internationalBankAccount_TC, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.internationalBankAccount_TW, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.internationalBankAccount_RD, component: LazyAdsRegisterPageMCA },

  { path: paths.ads.moneyTransfer_FC, component: LazyAdsRegisterPage },
  { path: paths.ads.moneyTransfer_MT, component: LazyAdsRegisterPage },
  { path: paths.ads.moneyTransfer_RD, component: LazyAdsRegisterPage },
  { path: paths.ads.moneyTransfer_TC, component: LazyAdsRegisterPage },
  { path: paths.ads.moneyTransfer_TW, component: LazyAdsRegisterPage },

  { path: paths.ads.multicurrencyAccount, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.multicurrencyAccount_A, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.multicurrencyAccount_B, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.multicurrencyAccount_C, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.multicurrencyAccount_D, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.multicurrencyAccount_E, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.multicurrencyAccount_FC, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.multicurrencyAccount_MT, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.multicurrencyAccount_PX, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.multicurrencyAccount_RD, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.multicurrencyAccount_TC, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.multicurrencyAccount_TW, component: LazyAdsRegisterPageMCA },

  { path: paths.ads.overseasBankAccount, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.overseasBankAccount_A, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.overseasBankAccount_B, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.overseasBankAccount_C, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.overseasBankAccount_D, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.overseasBankAccount_E, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.overseasBankAccount_FC, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.overseasBankAccount_MT, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.overseasBankAccount_RD, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.overseasBankAccount_TC, component: LazyAdsRegisterOffshoreAndOverseas },
  { path: paths.ads.overseasBankAccount_TW, component: LazyAdsRegisterOffshoreAndOverseas },

  { path: paths.ads.virtualDebitCard, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.virtualDebitCard_A, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.virtualDebitCard_B, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.virtualDebitCard_C, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.virtualDebitCard_D, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.virtualDebitCard_E, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.virtualDebitCard_CZ, component: LazyAdsRegisterPageMCA },
  { path: paths.ads.virtualDebitCard_MT, component: LazyAdsRegisterPageMCA }
]

export const logoutAnd404Routes = [
  { path: paths.logout(), component: React.lazy(() => import('@views/auth/Logout')) },
  { path: paths.error404(), component: LazyError404 },
  { path: '*', component: LazyError404 }
]

// prettier-ignore
export const adminRoutes = [
  { path: paths.root(), exact: true, component: Outlet },

  // { path: paths.ibanIssuingPreview(), component: IbanIssuingPreview},
  { path: paths.ibanIssuing(), component: IbanIssuing },
  { path: paths.scanAndPay(), component: ScanAndPay },
  { path: paths.p2pQrCode(), component: P2PQrCodePage, exact: true },
  { path: paths.dashboard(), component: Dashboard },
  { path: paths.profile(), component: ProfileSettings },
  { path: `${paths.profile()}/details`, component: ProfileSettings },
  { path: `${paths.profile()}/security`, component: ProfileSettings },
  { path: `${paths.profile()}/payment`, component: ProfileSettings },
  // { path: paths.security(), component: Security },
  // { path: paths.expenseManagement.paymentInfo(), component: PaymentInfo },
  { path: paths.userManagement(), component:UserManagement },

  { path: paths.introducingBrokersProgeny(':progenyId'), component: React.lazy(() => import('@views/ibPortal/IntroducingBrokersDetails')) },
  { path: paths.introducingBrokers(), component: React.lazy(() => import('@views/ibPortal/IntroducingBrokersPortal')) },
  { path: paths.referralProgram(), component: React.lazy(() => import('@views/referral/ReferralProgram')) },

  // { path: paths.banking.depositPreview(), exact: true, component: ApplicationFeeDeposit },
  { path: paths.banking.deposit(), component: LazyDeposit },
  { path: paths.banking.exchangeFromTo(':fromCurrency', ':toCurrency'), component: LazyExchange },
  { path: paths.banking.exchange(), component: LazyExchange },
  { path: paths.banking.sendCrypto(), exact: true, component: SendMoney },
  { path: paths.banking.sendPix(), exact: true, component: SendMoney },
  { path: paths.banking.sendInterac(), exact: true, component: SendMoney },
  { path: paths.banking.sendPerfectMoney(), exact: true, component: SendMoney },
  { path: paths.banking.sendTransfer(), exact: true, component: SendMoney },
  { path: paths.banking.sendBankTransfer(), exact: true, component: SendMoney },
  { path: paths.banking.sendDigitalWallet(), exact: true, component: SendMoney },
  { path: paths.banking.sendJetonWallet(), exact: true, component: SendMoney },
  { path: paths.banking.sendIntraAccountTransfer(), exact: true, component: SendMoney },
  { path: paths.banking.sendBatchPayment(), exact: true, component: BatchPayment },
  { path: paths.banking.sendTo(':recipientId'), component: SendMoney },
  { path: paths.banking.send(), component: SendMoney },

  // Expense Management
  { path: paths.expenseManagement.expenseManagementDashboard(), component: ExpenseManagementWrapper },
  { path: paths.expenseManagement.counterParty(), component: ExpenseManagementWrapper },
  { path: paths.expenseManagement.reports(), component: ExpenseManagementWrapper },
  { path: paths.expenseManagement.singleCounterparty(':counterpartyId'), exact: true, component: CounterpartyPage },
  { path: paths.expenseManagement.report(':reportId'), exact: true, component: LazyReportDetails },
  { path: paths.expenseManagement.payments(':reportId'), exact: true, component: PaymentsPage },
  { path: paths.expenseManagement.pay(), exact: true, component: PayPage },
  // Expense Management

  { path: paths.banking.accounts.list(), component: LazyAccounts },
  { path: paths.banking.transactions.listByAccountId(':accountId'), component: Transactions },
  { path: paths.banking.transactions.list(), component: Transactions },
  // { path: paths.banking.transactions.details(':transactionId'), component: TransactionDetails },

  { path: paths.recipients.details(':recipientId'), component: RecipientsDetails },
  { path: paths.recipients.list(), component: Recipients },

  { path: paths.more.savings(), component: React.lazy(() => import('@views/staking/Savings')) },
  // { path: paths.more.crypto(), component: React.lazy(() => import('@views/staking/CryptoStaking')) },

  { path: paths.more.cashVoucher('buy'), component: CashVoucher },
  { path: paths.more.cashVoucher('list'), component: CashVoucher },
  { path: paths.more.cashVoucher('redeem'), component: CashVoucher },
  // { path: paths.more.cashVoucherSuccess(':uuid'), component: CashVoucherSuccess },

  { path: paths.forex(), component: React.lazy(() => import('@views/forex/Forex')) },
  { path: paths.developer(), component: React.lazy(() => import('@views/developer/Developer')) },
  { path: paths.webTerminal(), component: React.lazy(() => import('@views/forex/WebTerminalNew')) },

  { path: paths.invoicesCustomersWrapper(), component: InvoicesCustomerWrapper},
  { path: paths.invoicesCustomersWrapper() + paths.customers(), component: InvoicesCustomerWrapper },
  { path: paths.invoicesCustomersWrapper() + paths.invoices.list(), component: InvoicesCustomerWrapper },

  { path: paths.invoices.uploadEdit(':invoiceId'), component: InvoiceUploadedEdit },
  { path: paths.invoices.upload(), component: InvoiceUploadedEdit },
  { path: paths.invoices.addForCustomer(':customerId'), component: InvoiceEdit },
  { path: paths.invoices.add(), component: InvoiceEdit },
  { path: paths.invoices.send(':invoiceId'), component: InvoiceSend },
  { path: paths.invoices.edit(':invoiceId'), component: InvoiceEdit },
  { path: paths.invoices.listByStatus(':status'), component: Invoices },

  { path: paths.onboarding.documents(), exact: true, component: React.lazy(() => import('@views/onboarding/AdditionalDocuments')) },
  { path: paths.onboarding.selectType(), exact: true, component: React.lazy(() => import('@views/onboarding/SumSubSelectType')) },
  { path: paths.onboarding.questionnaireKyb(), exact: true, component: React.lazy(() => import('@views/onboarding/QuestionnaireKYB')) },
  { path: paths.onboarding.sumSub.start(':merchantType'), exact: true, component: LazySumSubVerification },
  { path: paths.onboarding.sumSub.continue(), exact: true, component: LazySumSubVerification },

  { path: paths.banking.finalStatus.fail(':method'), component: React.lazy(() => import('@views/transaction_failed')) },
  { path: paths.banking.finalStatus.success(':method'), component: React.lazy(() => import('@views/transaction_success')) },
  { path: paths.cards.list(), component: React.lazy(() => import('@views/card')) },

  ...logoutAnd404Routes
]

export const submitterRoutes = [
  ...nonProtectedRoutes,
  { path: paths.root(), exact: true, component: Outlet },
  { path: paths.dashboard(), redirect: paths.expenseManagement.expenseManagementDashboard() },
  { path: paths.expenseManagement.expenseManagementDashboard(), exact: true, component: ExpenseDashboard },
  { path: paths.expenseManagement.report(':reportId'), exact: true, component: LazyReportDetails },
  { path: paths.profile(), component: ProfileSettings },
  { path: `${paths.profile()}/security`, component: ProfileSettings },
  { path: `${paths.profile()}/payment`, component: ProfileSettings },
  { path: paths.cards.list(), component: React.lazy(() => import('@views/card')) },
  ...logoutAnd404Routes
]

export const approverRoutes = [
  ...nonProtectedRoutes,
  { path: paths.root(), exact: true, component: Outlet },
  { path: paths.dashboard(), redirect: paths.expenseManagement.expenseManagementDashboard() },
  { path: paths.expenseManagement.expenseManagementDashboard(), exact: true, component: ExpenseDashboard },
  { path: paths.expenseManagement.report(':reportId'), exact: true, component: LazyReportDetails },
  { path: paths.profile(), component: ProfileSettings },
  { path: `${paths.profile()}/security`, component: ProfileSettings },
  { path: `${paths.profile()}/payment`, component: ProfileSettings },
  { path: paths.cards.list(), component: React.lazy(() => import('@views/card')) },
  ...logoutAnd404Routes
]

export const staffRoutes = [
  { path: paths.root(), exact: true, component: Outlet },

  { path: paths.scanAndPay(), component: ScanAndPay },
  { path: paths.p2pQrCode(), component: P2PQrCodePage, exact: true },
  { path: paths.dashboard(), component: Dashboard },
  { path: paths.profile(), component: ProfileSettings },
  { path: `${paths.profile()}/security`, component: ProfileSettings },

  { path: paths.banking.deposit(), component: LazyDeposit },
  { path: paths.banking.sendCrypto(), exact: true, component: SendMoney },
  { path: paths.banking.sendPix(), exact: true, component: SendMoney },
  { path: paths.banking.sendInterac(), exact: true, component: SendMoney },
  { path: paths.banking.sendPerfectMoney(), exact: true, component: SendMoney },
  { path: paths.banking.sendTransfer(), exact: true, component: SendMoney },
  { path: paths.banking.sendBankTransfer(), exact: true, component: SendMoney },
  { path: paths.banking.sendDigitalWallet(), exact: true, component: SendMoney },
  { path: paths.banking.sendJetonWallet(), exact: true, component: SendMoney },
  { path: paths.banking.sendIntraAccountTransfer(), exact: true, component: SendMoney },
  { path: paths.banking.sendTo(':recipientId'), component: SendMoney },
  { path: paths.banking.send(), component: SendMoney },

  { path: paths.banking.accounts.list(), component: LazyAccounts },
  { path: paths.banking.transactions.listByAccountId(':accountId'), component: Transactions },
  { path: paths.banking.transactions.list(), component: Transactions },
  // { path: paths.banking.transactions.details(':transactionId'), component: TransactionDetails },

  { path: paths.invoicesCustomersWrapper(), component: InvoicesCustomerWrapper },
  { path: paths.invoicesCustomersWrapper() + paths.customers(), component: InvoicesCustomerWrapper },
  { path: paths.invoicesCustomersWrapper() + paths.invoices.list(), component: InvoicesCustomerWrapper },

  { path: paths.invoices.uploadEdit(':invoiceId'), component: InvoiceUploadedEdit },
  { path: paths.invoices.upload(), component: InvoiceUploadedEdit },
  { path: paths.invoices.addForCustomer(':customerId'), component: InvoiceEdit },
  { path: paths.invoices.add(), component: InvoiceEdit },
  { path: paths.invoices.send(':invoiceId'), component: InvoiceSend },
  { path: paths.invoices.edit(':invoiceId'), component: InvoiceEdit },
  { path: paths.invoices.listByStatus(':status'), component: Invoices },
  { path: paths.cards.list(), component: React.lazy(() => import('@views/card')) },

  {
    path: paths.banking.finalStatus.fail(':method'),
    component: React.lazy(() => import('@views/transaction_failed'))
  },
  {
    path: paths.banking.finalStatus.success(':method'),
    component: React.lazy(() => import('@views/transaction_success'))
  },

  ...logoutAnd404Routes
]
