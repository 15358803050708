import { useEffect, useState } from 'react'
import cn from 'classnames'

import { tr } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { useCountries, useEditExternalWalletQry } from 'mmfintech-backend-api'
import { CountrySupportedPurposeEnum, ExternalWalletTypeEnum } from 'mmfintech-commons-types'

import { CoreButton, CoreInput, CoreSelect, CoreSteps, CoreSwitch } from '@components'

import '../styles/bankRecipientEdit.scss'

interface PixRecipientEditProps {
  recipientId?: number
  currencyCode?: string
  onSuccess?: (response: any) => void
  onClose: () => void
}

const stepOneFields = ['email', 'name', 'countryCode']

export const PixRecipientEdit = ({ recipientId, currencyCode, onClose, onSuccess }: PixRecipientEditProps) => {
  const [step, setStep] = useState<number>(1)
  const { countryOptions } = useCountries(CountrySupportedPurposeEnum.WITHDRAWAL)
  const { formValues, handleSubmit, error, fetching, showPixKeyInput, setShowPixKeyInput, resetMutations } =
    useEditExternalWalletQry({
      type: ExternalWalletTypeEnum.PIX,
      recipientId,
      currencyCode,
      onClose,
      onSuccess
    })

  const handleSubmitInternal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit()
  }

  useEffect(() => {
    const identifier = formValues.getValue('identifier')
    if (identifier && identifier === formValues.getValue('pixKey')) {
      setShowPixKeyInput(true)
    }
    if (identifier && identifier === formValues.getValue('documentId')) {
      setShowPixKeyInput(false)
    }
    return () => {
      resetMutations()
    }
  }, [formValues.getValue('identifier')])

  return (
    <div className='recipient-edit-wrapper'>
      <form className='styled-form' noValidate>
        <div className='recipient-edit-steps'>
          <CoreSteps
            maxStep={2}
            step={step}
            title={
              step === 1
                ? tr('FRONTEND.RECIPIENTS.EDIT.INFO.PERSONAL', 'Personal information')
                : tr('FRONTEND.RECIPIENTS.EDIT.INFO.PAYMENT', 'Payment information')
            }
          />
        </div>
        <div className='recipient-form-title'>
          {step === 1 ? (
            tr('FRONTEND.RECIPIENTS.EDIT.INFO.PERSONAL', 'Personal information')
          ) : (
            <>
              {tr('FRONTEND.RECIPIENTS.EDIT.INFO.PAYMENT', 'Payment information')}
              <div className='recipient-form-switch'>
                <div className={cn('recipient-tab-option', { active: !showPixKeyInput })}>
                  {tr('FRONTEND.RECIPIENTS.PIX.OTHER_TAB', 'Other')}
                </div>
                <CoreSwitch isActive={showPixKeyInput} handleSwitch={() => setShowPixKeyInput(!showPixKeyInput)} />
                <div className={cn('recipient-tab-option', { active: showPixKeyInput })}>
                  {tr('FRONTEND.RECIPIENTS.PIX.PIX_KEY_TAB', 'Pix key')}
                </div>
              </div>
            </>
          )}
        </div>
        <div className='form-elements-container'>
          {step === 1 && (
            <>
              <CoreInput
                type='email'
                data-test='email'
                label={tr('FRONTEND.RECIPIENTS.PIX.EMAIL', 'Email')}
                {...formValues.registerInput('email')}
                autoComplete='off'
              />
              <CoreInput
                type='text'
                data-test='name'
                label={tr('FRONTEND.RECIPIENTS.PIX.NAME', 'Name')}
                {...formValues.registerInput('name')}
                autoComplete='off'
              />
              <CoreSelect
                type='country'
                value={formValues.getValue('countryCode')}
                options={countryOptions}
                label={tr('FRONTEND.RECIPIENTS.PIX.COUNTRY', 'Country')}
                disabled
              />
            </>
          )}
          {step === 2 && (
            <>
              {showPixKeyInput ? (
                <CoreInput
                  type='text'
                  data-test='pixKey'
                  label={tr('FRONTEND.RECIPIENTS.PIX.PIX_KEY', 'Pix key')}
                  {...formValues.registerInput('pixKey')}
                  autoComplete='off'
                />
              ) : (
                <>
                  <CoreInput
                    type='text'
                    data-test='pixKey'
                    label={tr('FRONTEND.RECIPIENTS.PIX.HOLDER_NAME', 'Holder name')}
                    {...formValues.registerInput('holderName')}
                    autoComplete='off'
                  />
                  <CoreInput
                    type='text'
                    data-test='pixKey'
                    label={tr('FRONTEND.RECIPIENTS.PIX.NATIONAL_ID', 'National ID')}
                    {...formValues.registerInput('documentId')}
                    autoComplete='off'
                  />
                  <CoreInput
                    type='text'
                    data-test='pixKey'
                    label={tr('FRONTEND.RECIPIENTS.PIX.ISPB_CODE', 'ISPB code')}
                    {...formValues.registerInput('ispbCode')}
                    autoComplete='off'
                  />
                  <CoreInput
                    type='text'
                    data-test='pixKey'
                    label={tr('FRONTEND.RECIPIENTS.PIX.BRANCH_CODE', 'Branch code')}
                    {...formValues.registerInput('branchCode')}
                    autoComplete='off'
                  />
                  <CoreInput
                    type='text'
                    data-test='pixKey'
                    label={tr('FRONTEND.RECIPIENTS.PIX.ACCOUNT_NUMBER', 'Account number')}
                    {...formValues.registerInput('accountNumber')}
                    autoComplete='off'
                  />
                </>
              )}
              <CoreInput
                type='text'
                data-test='description'
                label={tr('FRONTEND.RECIPIENTS.PIX.DESCRIPTION', 'Description')}
                {...formValues.registerInput('description')}
                autoComplete='off'
              />
            </>
          )}
        </div>

        <ErrorDisplay error={[error]} />

        <div className='recipient-edit-actions'>
          {step === 2 && (
            <>
              <CoreButton
                data-test='save-button'
                type='button'
                variation='tertiary'
                size='large'
                text={tr('FRONTEND.RECIPIENTS.BUTTON.BACK', 'Back')}
                className='save-button'
                onClick={() => {
                  setStep(1)
                }}
                disabled={fetching}
              />
              <CoreButton
                data-test='save-button'
                type='button'
                variation='primary'
                size='large'
                fullWidth
                text={tr('FRONTEND.RECIPIENTS.BUTTON.SAVE', 'Save')}
                className='save-button'
                onClick={async e => {
                  e.preventDefault()
                  await handleSubmitInternal(e)
                  stepOneFields.forEach(key => {
                    if (formValues.values[key].error) {
                      setStep(1)
                    }
                  })
                }}
                isLoading={fetching}
              />
            </>
          )}
          {step === 1 && (
            <CoreButton
              data-test='save-button'
              type='button'
              variation='primary'
              size='large'
              fullWidth
              text={tr('FRONTEND.RECIPIENTS.BUTTON.NEXT', 'Next')}
              className='save-button'
              onClick={() => {
                setStep(2)
              }}
              disabled={fetching}
            />
          )}
        </div>
      </form>
    </div>
  )
}
