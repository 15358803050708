import '../styles/cryptoRecipientEdit.scss'
import { useEditCryptoAddressQry, useWithdrawAccounts } from 'mmfintech-backend-api'
import { CoreButton, CoreInput, CoreSelect } from '@components'
import { tr } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { WithdrawalSubFlowEnum } from 'mmfintech-commons-types'

export const CryptoRecipientEdit = ({
  recipientId,
  currencyCode,
  onClose,
  onSuccess
}: {
  recipientId?: number
  currencyCode?: string
  onClose?: () => void
  onSuccess?: (response?: any) => void
}) => {
  const { formValues, isTagRequired, error, fetching, handleSubmit } = useEditCryptoAddressQry({
    recipientId,
    currencyCode,
    settlement: false,
    onClose,
    onSuccess
  })

  const { accounts, accountsError, accountsFetching } = useWithdrawAccounts({
    flowType: WithdrawalSubFlowEnum.CRYPTO,
    recipientId
  })

  const handleSubmitInternal = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    handleSubmit()
  }
  return (
    <div className='recipient-edit-wrapper' data-test='crypto-recipient-edit-modal'>
      <div className='recipient-edit-title'>
        {tr('FRONTEND.RECIPIENTS.LABELS.CRYPTO_INFORMATION', 'Payment information')}
      </div>
      <form className='recipient-edit-form' noValidate onSubmit={handleSubmitInternal}>
        <CoreInput
          type='text'
          data-test='account-name'
          label={tr('FRONTEND.RECIPIENTS.LABELS.ACCOUNT_NAME', 'Recipient name')}
          maxLength={100}
          {...formValues.registerInput('name')}
          autoComplete='off'
        />

        {currencyCode ?
          <CoreInput
            type='text'
            data-test='address'
            label={tr('FRONTEND.RECIPIENTS.LABELS.CURRENCY', 'Currency')}
            {...formValues.registerInput('currency')}
            autoComplete='off'
            disabled
          /> : <CoreSelect
            type='account'
            options={accounts}
            loading={accountsFetching}
            error={accountsError || formValues.getError('currency')}
            label={tr('FRONTEND.RECIPIENTS.LABELS.CRYPTO_ACCOUNT', 'Account')}
            onChange={(_, acc) => formValues.setValue('currency', acc?.currencyCode)}
          />
        }

        <CoreInput
          type='text'
          data-test='address'
          label={tr('FRONTEND.RECIPIENTS.LABELS.CRYPTO_ADDRESS', 'Address')}
          {...formValues.registerInput('address')}
          autoComplete='off'
        />

        {isTagRequired() && (
          <CoreInput
            type='text'
            data-test='tag'
            label={tr('FRONTEND.RECIPIENTS.LABELS.TAG', 'Tag')}
            {...formValues.registerInput('tag')}
            autoComplete='off'
          />
        )}

        <CoreInput
          type='text'
          data-test='description'
          label={tr('FRONTEND.RECIPIENTS.LABELS.DESCRIPTION', 'Description')}
          maxLength={100}
          {...formValues.registerInput('description')}
          autoComplete='off'
        />

        <ErrorDisplay error={error} />

        <div className='recipient-edit-actions'>
          <CoreButton
            data-test='save-button'
            variation='primary'
            size='large'
            text={tr('FRONTEND.RECIPIENTS.BUTTON.SAVE', 'Save')}
            className='save-button'
            onClick={async e => {
              e.preventDefault()
              handleSubmit()
            }}
            isLoading={fetching}
            disabled={fetching}
            fullWidth
          />
        </div>
      </form>
    </div>
  )
}
