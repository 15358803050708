import { ReactNode } from 'react'
import './sendMoneyValueLabel.scss'
import { AccountBalanceResponse } from 'mmfintech-commons-types'
import { formatMoney, tr } from 'mmfintech-commons'
import { SelectionElement } from '../../CoreSelect'

export const BetweenAccountsSendMoneyValueLabel = ({
  dataTest,
  account,
  toAmount,
  walletAmount,
  walletCurrency,
  processingCurrency
}: {
  label?: string
  value?: string | number | ReactNode
  dataTest?: string
  account?: AccountBalanceResponse
  toAmount?: number | string
  walletAmount?: number
  walletCurrency?: string
  processingCurrency?: string
}) => {
  const amount = walletAmount || toAmount
  const currency = walletCurrency || processingCurrency

  return (
    <>
      <div className='between-accounts-value-label' data-test={dataTest}>
        <span className='send-money-label'>{tr('FRONTEND.SEND_BETWEEN_ACCOUNTS.AMOUNT_LABEL', 'Amount')}</span>
        <span className='send-money-value'>{formatMoney(amount, currency)}</span>
      </div>
      <div className='between-accounts-value-label' data-test={dataTest}>
        <span className='send-money-label'>{tr('FRONTEND.SEND_BETWEEN_ACCOUNTS.ACCOUNT_LABEL', 'Account')}</span>
        <div className='send-money-account'>
          <SelectionElement data-test='selected-content' selectedList={[]} type='account' data={account} hideCaption />
        </div>
      </div>
    </>
  )
}

export const SendMoneyValueLabel = ({
  label,
  value,
  dataTest
}: {
  label: string
  value: string | number | ReactNode
  dataTest?: string
}) => {
  if (!value) return null

  return (
    <div className='send-money-value-label' data-test={dataTest}>
      <span className='send-money-label'>{label}</span>
      <span className='send-money-value'>{value}</span>
    </div>
  )
}
