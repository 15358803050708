import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { GlobalContext, isValidArray, OtpContext, tr } from 'mmfintech-commons'
import {
  responseDoesNotContainsChallenge,
  selectMerchantAccountType,
  selectMerchantStatus,
  useAppSelector,
  useReturnToOrigin,
  useWalletTransferQry
} from 'mmfintech-backend-api'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { HeaderContext } from '../../../../context/HeaderContext'
import { WalletRecipientEdit } from '@views/recipients/elements/WalletRecipientEdit'
import { WalletTransferPreview } from '../WalletTransferPreview'
import {
  CoreApplicationFeeAnnounce,
  CoreButton,
  CoreInput,
  CoreOnboardingAnnounce,
  CorePaymentInput,
  CoreSelect,
  SuccessPane
} from '@components'

import {
  ExternalWalletTypeEnum,
  MerchantAccountTypeEnum,
  OnboardingStatusEnum,
  PaymentOptionStatusEnum,
  WithdrawalSubFlowEnum
} from 'mmfintech-commons-types'

import NewRecipientIcon from '@images/icons/recipient-new.svg?react'
import RecipientIcon from '@images/icons/recipient-select.svg?react'
import ReferenceIcon from '@images/icons/note.svg?react'

export const DigitalWalletTransfer = () => {
  const { setCurrentStep, currentStep } = useContext(HeaderContext)
  const { modalHide, modalShow } = useContext(GlobalContext)
  const { setOtpOnSuccess } = useContext(OtpContext)

  const [result, setResult] = useState(null)

  const history = useHistory()

  const accountType = useAppSelector(selectMerchantAccountType)
  const onboardingStatus = useAppSelector(selectMerchantStatus)

  const { returnToOrigin } = useReturnToOrigin()

  const redirectPath = returnToOrigin()

  const {
    formValues,
    handleRecipientChange,
    handlePreview,
    handleInitiate,
    reloadRecipients,
    selectedAccount,
    selectedRecipient,
    setSelectedAccount,
    filteredRecipients,
    shouldShowWarning,
    accounts,
    accountsError,
    accountsFetching,
    recipientsError,
    recipientsFetching,
    previewFetching,
    previewError,
    resetMutations
  } = useWalletTransferQry({
    flowType: WithdrawalSubFlowEnum.DIGITAL_WALLET,
    editRecipient: () => handleEditRecipient(),
    onPreviewSuccess: () => setCurrentStep(2),
    onInitiateSuccess: (data: any) => handleInitiateSuccess(data),
    selectAddNew: false
  })

  const handleEditRecipient = (recipient?: any): void => {
    const { currencyCode, supportedCountries } = selectedAccount || {}
    if (currencyCode) {
      modalShow({
        header: recipient?.paymentInstrumentId
          ? tr('FRONTEND.RECIPIENTS.JETON.UPDATE_TITLE', 'Edit recipient')
          : tr('FRONTEND.RECIPIENTS.JETON.CREATE_TITLE', 'Create recipient'),
        content: (
          <WalletRecipientEdit
            walletType={ExternalWalletTypeEnum.JETON_WALLET}
            recipientId={recipient?.paymentInstrumentId}
            currencyCode={currencyCode}
            supportedCountries={supportedCountries}
            onClose={modalHide}
            onSuccess={(response: any) => {
              const { paymentInstrumentId } = response || {}
              reloadRecipients(paymentInstrumentId)
            }}
          />
        )
      })
    }
  }

  const transferSuccess = (data: any): void => {
    setResult(data)
    setCurrentStep(3)
  }

  const handleInitiateSuccess = (data: any): void => {
    if (responseDoesNotContainsChallenge(data)) {
      transferSuccess(data)
    }
  }
  const handleAdvancedInitiate = () => {
    setOtpOnSuccess(() => transferSuccess)
    handleInitiate()
  }

  const hasValidRecipientPaymentOption = () => {
    const { paymentOption } = selectedRecipient || {}
    const { status } = paymentOption || {}
    return status === PaymentOptionStatusEnum.AVAILABLE
  }

  const hasValidPaymentOption = () => {
    const { paymentOptions } = selectedAccount || {}

    return (
      hasValidRecipientPaymentOption() ||
      (isValidArray(paymentOptions) && paymentOptions[0].status === PaymentOptionStatusEnum.AVAILABLE)
    )
  }

  useEffect(() => {
    return () => {
      setCurrentStep(1)
      setResult(null)
      resetMutations()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {currentStep === 1 && (
        <div className='send-money-container' data-test='bank-transfer-container'>
          <div className='from-container'>
            <div className='label'>
              <span>From</span>
            </div>
            <CorePaymentInput
              selectedOption={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              dataTest={'deposit-payment-input-test'}
              options={accounts}
              enableMaxAmountButton
              paymentOption={selectedRecipient?.paymentOption}
              onChange={(value: any) => formValues.setValue('amount', value)}
              value={formValues.getValue('amount')}
              error={formValues.getError('amount')}
              isLoading={accountsFetching}
            />
          </div>

          <div>
            <div className='to-container'>
              <div className='container-sb'>
                <span className='label'>{tr('FRONTEND.WITHDRAW.BANK_TRANSFER.TO_LABEL', 'To')}</span>

                <CoreButton
                  variation='tertiary'
                  LeftIcon={<NewRecipientIcon />}
                  className='send-money-add-recipient'
                  size='normal'
                  text={tr('FRONTEND.RECIPIENTS.LIST.BUTTON_ADD', 'New recipient')}
                  isLoading={previewFetching}
                  onClick={() => handleEditRecipient()}
                  data-test='button-add-recipient'
                />
              </div>
              <CoreSelect
                type='default'
                data-test='destination-payment-instrument-id'
                label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.RECIPIENT', 'Recipient')}
                options={filteredRecipients}
                onChange={handleRecipientChange}
                loading={recipientsFetching}
                disabled={recipientsFetching}
                LeftIcon={<RecipientIcon />}
                value={
                  selectedRecipient
                    ? { value: selectedRecipient?.paymentInstrumentId, label: selectedRecipient?.name }
                    : null
                }
              />
            </div>
          </div>

          <CoreInput
            type='text'
            name='reference'
            data-test='reference'
            maxLength={50}
            label={tr('FRONTEND.WITHDRAW.BANK_TRANSFER.REFERENCE', 'Reference')}
            {...formValues.registerInput('reference')}
            autoComplete='off'
            LeftIcon={<ReferenceIcon />}
          />

          <div className='mb-4' />

          {shouldShowWarning() || (accountType === MerchantAccountTypeEnum.PROSPECT && !hasValidPaymentOption()) ? (
            <div className='warning-banner'>
              <CoreOnboardingAnnounce />
            </div>
          ) : null}

          {accountType === MerchantAccountTypeEnum.PROSPECT && onboardingStatus === OnboardingStatusEnum.APPROVED && (
            <div className='warning-banner'>
              <CoreApplicationFeeAnnounce />
            </div>
          )}

          <ErrorDisplay error={[accountsError, recipientsError, previewError]} />

          <CoreButton
            type='button'
            size='large'
            text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
            disabled={
              accountsFetching ||
              recipientsFetching ||
              !hasValidRecipientPaymentOption() ||
              formValues.getFloat('amount') <= 0
            }
            isLoading={previewFetching}
            onClick={handlePreview}
            data-test='button-continue'
            fullWidth
          />
        </div>
      )}

      {currentStep === 2 && (
        <WalletTransferPreview
          formValues={formValues}
          selectedAccount={selectedAccount}
          selectedRecipient={selectedRecipient}
          onSubmit={handleAdvancedInitiate}
          onCancel={() => setCurrentStep(1)}
        />
      )}

      {currentStep === 3 && <SuccessPane response={result} onClick={() => history.push(redirectPath)} />}
    </>
  )
}
