import {
  api,
  getRecipientAccountTypeName,
  IdType,
  useDocumentTypes,
  useGetBankMetadataByRecipientIdQuery,
  useGetBankMetadataQuery,
  useGetRecipientBanksQuery
} from 'mmfintech-backend-api'
import {
  fixDateOnly,
  fixPhoneNumber,
  isNotEmptyString,
  isValidArray,
  isValidObject,
  packObject
} from 'mmfintech-commons'
import {
  AddressDTO,
  BankAccountOut,
  BankFieldInputTypeEnum,
  BankFieldMetadata,
  BankOut,
  CreateExternalBankAccountRequest,
  InstrumentFieldEnum,
  NationalIdDetails,
  PatchExternalBankAccountRequest,
  SelectOptions
} from 'mmfintech-commons-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const loadBankAccountIntoFormValues = (formValues: any, bankAccount: BankAccountOut): void => {
  const setValue = (key: string, value: any): void => {
    if (isNotEmptyString(value)) {
      formValues.setValue(key, value)
    }
  }

  // currency
  setValue('name', bankAccount.holderName)
  setValue('iban', bankAccount.iban)
  setValue('interbankAccountCode', bankAccount.interbankAccountCode)
  setValue('bicSwift', bankAccount.bicSwift)
  setValue('accountNumber', bankAccount.accountNumber)
  setValue('bankCode', bankAccount.bankCode)
  setValue('branchCode', bankAccount.branchCode)
  setValue('accountType', bankAccount.accountType)
  setValue('holderName', bankAccount.holderName)
  setValue('altHolderName', bankAccount.altHolderName)
  setValue('holderFirstName', bankAccount.holderFirstName)
  setValue('holderLastName', bankAccount.holderLastName)
  setValue('holderEmail', bankAccount.holderEmail)
  setValue('holderPhone', bankAccount.holderPhone)
  setValue('holderGender', bankAccount.holderGender)
  setValue('holderDateOfBirth', bankAccount.holderDateOfBirth)
  setValue('documentId', bankAccount.documentId)
  setValue('documentType', bankAccount.documentType)
  setValue('entityType', bankAccount.entityType)
  setValue('bankName', bankAccount.bankName)
  setValue('branchName', bankAccount.branchName)
  // country
  setValue('pixKey', bankAccount.pixKey)
  setValue('title', bankAccount.title)
  setValue('ispbCode', bankAccount.ispbCode)
  setValue('alternativeAccountNumber', bankAccount.alternativeAccountNumber)
  setValue('alternativeAccountType', bankAccount.alternativeAccountType)
  setValue('foreignId', bankAccount.foreignId)
  setValue('ownAccount', bankAccount.ownAccount)
  // settlement
  setValue('description', bankAccount.description)
  if (bankAccount.holderAddress) {
    setValue('holderAddress.countryCode', bankAccount.holderAddress.countryCode)
    setValue('holderAddress.addressLine1', bankAccount.holderAddress.addressLine1)
    setValue('holderAddress.addressLine2', bankAccount.holderAddress.addressLine2)
    setValue('holderAddress.city', bankAccount.holderAddress.city)
    setValue('holderAddress.street', bankAccount.holderAddress.street)
    setValue('holderAddress.streetNumber', bankAccount.holderAddress.streetNumber)
    setValue('holderAddress.neighbourhood', bankAccount.holderAddress.neighbourhood)
    setValue('holderAddress.region', bankAccount.holderAddress.region)
    setValue('holderAddress.postalCode', bankAccount.holderAddress.postalCode)
  }
  if (bankAccount.bankAddress) {
    setValue('bankAddress.countryCode', bankAccount.bankAddress.countryCode)
    setValue('bankAddress.addressLine1', bankAccount.bankAddress.addressLine1)
    setValue('bankAddress.addressLine2', bankAccount.bankAddress.addressLine2)
    setValue('bankAddress.city', bankAccount.bankAddress.city)
    setValue('bankAddress.street', bankAccount.bankAddress.street)
    setValue('bankAddress.streetNumber', bankAccount.bankAddress.streetNumber)
    setValue('bankAddress.neighbourhood', bankAccount.bankAddress.neighbourhood)
    setValue('bankAddress.region', bankAccount.bankAddress.region)
    setValue('bankAddress.postalCode', bankAccount.bankAddress.postalCode)
  }
}

export const prepareBankAccountCreateRequest = (
  formValues: any,
  accountId: number,
  countryCode: string,
  currencyCode: string,
  settlement: boolean
): CreateExternalBankAccountRequest =>
  packObject({
    ...prepareBankAccountDetails(formValues, countryCode, settlement),
    currency: currencyCode,
    accountId
  })

export const prepareBankAccountDetails = (formValues: any, countryCode: string, settlement: boolean) => ({
  iban: formValues.getValue('iban'),
  interbankAccountCode: formValues.getValue('interbankAccountCode'),
  bicSwift: formValues.getValue('bicSwift'),
  accountNumber: formValues.getValue('accountNumber'),
  bankCode: formValues.getValue('bankCode'),
  branchCode: formValues.getValue('branchCode'),
  accountType: formValues.getValue('accountType'),
  holderName: formValues.getValue('name'),
  altHolderName: formValues.getValue('altHolderName'),
  holderFirstName: formValues.getValue('holderFirstName'),
  holderLastName: formValues.getValue('holderLastName'),
  holderEmail: formValues.getValue('holderEmail'),
  holderPhone: fixPhoneNumber(formValues.getValue('holderPhone')),
  holderGender: formValues.getValue('holderGender'),
  holderDateOfBirth: fixDateOnly(formValues.getValue('holderDateOfBirth')),
  documentId: formValues.getValue('documentId'),
  documentType: formValues.getValue('documentType'),
  entityType: formValues.getValue('entityType'),

  bankName: formValues.getValue('bankName'),
  branchName: formValues.getValue('branchName'),

  country: countryCode,
  pixKey: formValues.getValue('pixKey'),
  title: formValues.getValue('title'),
  ispbCode: formValues.getValue('ispbCode'),

  alternativeAccountNumber: formValues.getValue('alternativeAccountNumber'),
  alternativeAccountType: formValues.getValue('alternativeAccountType'),

  foreignId: formValues.getValue('foreignId'),
  ownAccount: formValues.getValue('ownAccount'),
  settlement,

  description: formValues.getValue('description'),

  holderAddress: prepareAddress({
    countryCode: formValues.getValue('holderAddress.countryCode'),
    addressLine1: formValues.getValue('holderAddress.addressLine1'),
    addressLine2: formValues.getValue('holderAddress.addressLine2'),
    city: formValues.getValue('holderAddress.city'),
    street: formValues.getValue('holderAddress.street'),
    streetNumber: formValues.getValue('holderAddress.streetNumber'),
    neighbourhood: formValues.getValue('holderAddress.neighbourhood'),
    region: formValues.getValue('holderAddress.region'),
    postalCode: formValues.getValue('holderAddress.postalCode')
  }),
  bankAddress: prepareAddress({
    countryCode: formValues.getValue('bankAddress.countryCode'),
    addressLine1: formValues.getValue('bankAddress.addressLine1'),
    addressLine2: formValues.getValue('bankAddress.addressLine2'),
    city: formValues.getValue('bankAddress.city'),
    street: formValues.getValue('bankAddress.street'),
    streetNumber: formValues.getValue('bankAddress.streetNumber'),
    neighbourhood: formValues.getValue('bankAddress.neighbourhood'),
    region: formValues.getValue('bankAddress.region'),
    postalCode: formValues.getValue('bankAddress.postalCode')
  })
})

export const prepareAddress = (address: AddressDTO): AddressDTO => {
  const { countryCode, addressLine1, addressLine2, city, street, streetNumber, neighbourhood, region, postalCode } =
    address

  if (
    isNotEmptyString([
      countryCode,
      addressLine1,
      addressLine2,
      city,
      street,
      streetNumber,
      neighbourhood,
      region,
      postalCode
    ])
  ) {
    return {
      countryCode,
      addressLine1,
      addressLine2,
      city,
      street,
      streetNumber,
      neighbourhood,
      region,
      postalCode
    }
  }
  return null
}

export const prepareBankAccountUpdateRequest = (
  formValues: any,
  accountId: number,
  countryCode: string,
  currencyCode: string,
  settlement: boolean
): PatchExternalBankAccountRequest =>
  packObject({
    ...prepareBankAccountDetails(formValues, countryCode, settlement),
    currency: currencyCode,
    accountId
  })

interface BankMetadataRequest {
  countryCode: string
  currencyCode: string
  accountId?: number
  settlement?: boolean
}

interface BankMetadataProps extends BankMetadataRequest {
  enableProvidedBanks?: boolean
  recipientId?: IdType
  independentEdit?: boolean
}

interface BankMetadataOutput {
  bankMetadata: BankFieldMetadata[]
  bankMetadataError: any
  bankMetadataFetching: boolean
  accountTypeOptions: () => SelectOptions
  documentTypes: NationalIdDetails[]
  documentTypesOptions: SelectOptions
  documentTypesFetching: boolean
  documentTypesError: any
  providedBanks: BankOut[]
  initializeFormValues: (formValues: any) => void
}

export const useBankMetadata = (props: BankMetadataProps): BankMetadataOutput => {
  const { countryCode, currencyCode, accountId, settlement, enableProvidedBanks, recipientId, independentEdit } = props

  const dispatch = useDispatch()

  const { bankMetadata, bankMetadataError, bankMetadataFetching, shouldFetchBanks, shouldFetchDocumentTypes } =
    recipientId && independentEdit ?
      useGetBankMetadataByRecipientIdQuery(
        { recipientId },
        {
          refetchOnMountOrArgChange: true,
          skip: !currencyCode || !countryCode,
          selectFromResult: ({ data, error, isFetching }) => {
            const metadata = isValidArray(data?.fields) && !error ? data.fields : []
            return {
              bankMetadata: metadata,
              bankMetadataError: error,
              bankMetadataFetching: isFetching,
              shouldFetchBanks:
                isValidArray(metadata) && metadata.some(node => node.code === InstrumentFieldEnum.BANK_NAME),
              shouldFetchDocumentTypes:
                isValidArray(metadata) && metadata.some(node => node.code === InstrumentFieldEnum.NATIONAL_ID_TYPE)
            }
          }
        }
      ) :
      useGetBankMetadataQuery(
        { countryCode, currencyCode, accountId, settlement },
        {
          refetchOnMountOrArgChange: true,
          skip: !currencyCode || !countryCode,
          selectFromResult: ({ data, error, isFetching }) => {
            const metadata = isValidArray(data?.fields) && !error ? data.fields : []
            return {
              bankMetadata: metadata,
              bankMetadataError: error,
              bankMetadataFetching: isFetching,
              shouldFetchBanks:
                isValidArray(metadata) && metadata.some(node => node.code === InstrumentFieldEnum.BANK_NAME),
              shouldFetchDocumentTypes:
                isValidArray(metadata) && metadata.some(node => node.code === InstrumentFieldEnum.NATIONAL_ID_TYPE)
            }
          }
        }
      )

  const { documentTypes, documentTypesOptions, documentTypesError, documentTypesFetching } = useDocumentTypes(
    countryCode,
    shouldFetchDocumentTypes
  )

  const { data: providedBanks } = useGetRecipientBanksQuery(countryCode, {
    refetchOnMountOrArgChange: true,
    skip: !countryCode || !shouldFetchBanks || !enableProvidedBanks
  })

  const initializeFormValues = (formValues: any): void => {
    formValues.cleanup(['currency', 'name', 'holderName', 'description', 'foreignId'])

    bankMetadata.forEach((meta: BankFieldMetadata) => {
      const {
        code,
        fieldType,
        mandatory,
        minLength,
        maxLength,
        placeholder,
        tooltipTranslationKey,
        fieldCategory,
        fieldName
      } = meta

      if (code === InstrumentFieldEnum.COUNTRY_CODE) {
        // skip
      } else if (code === InstrumentFieldEnum.HOLDER_NAME) {
        formValues.setupField({
          name: 'name',
          required: mandatory,
          validation: 'name',
          maxLength,
          minLength,
          placeholder,
          tooltip: tooltipTranslationKey,
          step: 1
        })
      } else {
        formValues.setupField({
          name: fieldName,
          required: mandatory,
          validation: getBankMetadataFieldValidation(meta),
          ...(fieldType === BankFieldInputTypeEnum.SELECTION ? null : { maxLength, minLength }),
          placeholder,
          tooltip: tooltipTranslationKey,
          step: fieldCategory === 'BANK_DETAILS' ? 1 : 2
        })
      }
    })
  }

  const accountTypeOptions = (): SelectOptions => {
    const result = getRecipientAccountTypeOptions()
    const found = bankMetadata?.find((v: BankFieldMetadata) => v.code === 'ACCOUNT_TYPE')
    if (found) {
      const test = new RegExp(found.regex)
      return result.filter(v => test.test(v.value))
    }
    return result
  }

  useEffect(() => {
    if (!currencyCode || !countryCode) {
      dispatch(api.util.invalidateTags(['BankMetadata', 'BankMetadataBanks', 'BankMetadataDocumentTypes']))
    }
  }, [currencyCode, countryCode])

  return {
    bankMetadata,
    bankMetadataError,
    bankMetadataFetching,
    accountTypeOptions,
    documentTypes,
    documentTypesOptions,
    documentTypesFetching,
    documentTypesError,
    providedBanks,
    initializeFormValues
  }
}

const getBankMetadataFieldValidation = (metadata: BankFieldMetadata) => {
  const { code, digitsOnly } = metadata || {}
  if (code === InstrumentFieldEnum.IBAN) {
    return 'iban'
  }
  if (code === InstrumentFieldEnum.BIC_SWIFT) {
    return 'bic_swift'
  }
  return digitsOnly ? 'numeric' : 'safe-string'
}

const getRecipientAccountTypeOptions = () => [
  { value: 'CURRENT', label: getRecipientAccountTypeName('CURRENT') },
  { value: 'SAVINGS', label: getRecipientAccountTypeName('SAVINGS') },
  { value: 'CHECKING', label: getRecipientAccountTypeName('CHECKING') }
]

export const splitBankRecipientData = (formValues: any) => {
  const personalInformation = [
    'name',
    'holderName',
    'altHolderName',
    'holderFirstName',
    'holderLastName',
    'holderEmail',
    'holderPhone',
    'holderGender',
    'holderDateOfBirth',
    'documentId',
    'documentType',
    'entityType',
    'description',
    'holderAddress.countryCode',
    'holderAddress.addressLine1',
    'holderAddress.addressLine2',
    'holderAddress.city',
    'holderAddress.street',
    'holderAddress.streetNumber',
    'holderAddress.neighbourhood',
    'holderAddress.region',
    'holderAddress.postalCode'
  ]
  const paymentInformation = [
    'iban',
    'interbankAccountCode',
    'bicSwift',
    'accountNumber',
    'bankCode',
    'branchCode',
    'accountType',
    'bankAddress.countryCode',
    'bankAddress.addressLine1',
    'bankAddress.addressLine2',
    'bankAddress.city',
    'bankAddress.street',
    'bankAddress.streetNumber',
    'bankAddress.neighbourhood',
    'bankAddress.region',
    'bankAddress.postalCode',
    'bankName',
    'branchName',
    'pixKey',
    'title',
    'ispbCode',
    'alternativeAccountNumber',
    'alternativeAccountType',
    'foreignId',
    'ownAccount'
  ]

  if (isValidObject(formValues.values)) {
    return Object.entries(formValues.values).reduce(
      (prev, cur) => {
        const [key, value] = cur as [string, any]
        if (personalInformation.includes(key)) {
          prev.personalInformation[key] = value
        } else if (paymentInformation.includes(key)) {
          prev.paymentInformation[key] = value
        }
        return prev
      },
      { personalInformation: {}, paymentInformation: {} }
    )
  } else {
    return null
  }
}

export const recipientKeyToCode = (key: string): string => {
  const reference = {
    accountNumber: 'ACCOUNT_NUMBER',
    accountType: 'ACCOUNT_TYPE',
    bankCode: 'BANK_CODE',
    bankName: 'BANK_NAME',
    bicSwift: 'BIC_SWIFT',
    branchCode: 'BRANCH_CODE',
    branchName: 'BRANCH_NAME',
    description: 'DESCRIPTION',
    documentId: 'NATIONAL_ID',
    documentType: 'TYPE',
    entityType: 'ENTITY_TYPE',
    holderDateOfBirth: 'DATE_OF_BIRTH',
    holderEmail: 'EMAIL',
    holderFirstName: 'FIRST_NAME',
    holderLastName: 'LAST_NAME',
    holderName: 'HOLDER_NAME',
    holderPhone: 'PHONE',
    iban: 'IBAN',
    interbankAccountCode: 'INTERBANK_ACCOUNT_CODE',
    name: 'ACCOUNT_NAME',
    'bankAddress.city': 'BANK_CITY',
    'bankAddress.countryCode': 'BANK_COUNTRY',
    'bankAddress.postalCode': 'BANK_POST_CODE',
    'bankAddress.region': 'BANK_REGION',
    'bankAddress.street': 'BANK_STREET',
    'holderAddress.city': 'HOLDER_CITY',
    'holderAddress.countryCode': 'HOLDER_COUNTRY',
    'holderAddress.postalCode': 'HOLDER_POST_CODE',
    'holderAddress.region': 'HOLDER_REGION',
    'holderAddress.street': 'HOLDER_STREET',
    'holderAddress.addressLine1': 'HOLDER_STREET',
    'holderAddress.addressLine2': 'HOLDER_STREET'
    //IDENTIFIER
    //CRYPTO_ADDRESS
    //CRYPTO_CURRENCY
  }

  return reference[key] || ''
}
