import { formatFloat, getCurrencyDecimals, tr } from 'mmfintech-commons'
import './IbanRequestSuccess.scss'
import { CoreButton, CoreCurrencyIcon } from '@components'
import { AccountBalanceResponse, BankAccountFeeResponse, BankAccountScheme, List } from 'mmfintech-commons-types'

import CheckIcon from '@images/icons/check-icon.svg?react'
import { useGetMerchantQuery } from 'mmfintech-backend-api'

interface IbanRequestSuccessProps {
  selectedScheme: BankAccountScheme
  onSuccess: () => void
  selectedCurrency: string
  selectedAccount: AccountBalanceResponse
  fees: List<BankAccountFeeResponse>
}

export const IbanRequestSuccessPane = ({
  onSuccess,
  selectedScheme,
  selectedCurrency,
  selectedAccount,
  fees
}: IbanRequestSuccessProps) => {
  const { data: merchant } = useGetMerchantQuery(null)
  const { capabilities } = (merchant as any) || {}
  return (
    <div className='iban-request-success-pane-wrapper'>
      <div className='iban-request-success-pane-container'>
        <div className='iban-request-success-pane-icon-wrapper'>
          <div className='iban-request-success-pane-icon'>
            <div className='iban-request-success-pane-icon-inner'>
              <CheckIcon />
            </div>
          </div>
        </div>
        <span className='iban-request-success-pane-title'>
          {tr('FRONTEND.IBAN_REQUEST.SUCCESS_PANE_MESSAGE', 'IBAN successfully request!')}
        </span>
        <div className='iban-request-success-pane-section'>
          <span className='iban-request-success-pane-section-title'>
            {tr('FRONTEND.IBAN_REQUEST.SUMMARY_LABEL', 'Summary')}
          </span>
          <div className='iban-request-success-pane-summary-wrapper'>
            <div className='iban-request-success-pane-summary'>
              <span className='iban-request-success-pane-summary-title'>
                {tr('FRONTEND.IBAN_REQUEST_REVIEW.PAYMENT_NETWORK', 'Payment network')}
              </span>
              <span className='iban-request-success-pane-summary-content'>{selectedScheme}</span>
            </div>
            <div className='iban-request-success-pane-summary'>
              <span className='iban-request-success-pane-summary-title'>
                {tr('FRONTEND.IBAN_REQUEST_REVIEW.CURRENCY_LABEL', 'Currency')}
              </span>
              <span className='iban-request-success-pane-summary-content'>{selectedCurrency}</span>
            </div>
            <div className='iban-request-success-pane-summary'>
              <span className='iban-request-success-pane-summary-title'>
                {tr('FRONTEND.IBAN_REQUEST_REVIEW.SELECTED_ACCOUNT_LABEL', 'Selected Account')}
              </span>
              <span className='iban-request-success-pane-summary-content'>
                <CoreCurrencyIcon currency={selectedAccount?.currencyCode} circle /> {selectedAccount?.name}
              </span>
            </div>
          </div>
        </div>
        {capabilities?.enableIbanIssuingFee && (
          <div className='iban-request-fee-wrapper'>
            <div>
              {fees.map((fee: any) => {
                const { feeType, feeCurrency, value } = fee
                return (
                  <div className='iban-request-fee-content' key={feeType}>
                    <div className='fee-title'>
                      {tr(
                        `FRONTEND.IBAN_ISSUING.${feeType}`,
                        feeType === 'IBAN_ISSUING_FEE' ? 'Issuing fee:' : 'Monthly maintenance fee:'
                      )}
                    </div>
                    <div className='fee-amount'>
                      {formatFloat(value, getCurrencyDecimals(feeCurrency))}{' '}
                      <span className='fee-amount-currency'>{feeCurrency}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <CoreButton
          fullWidth={true}
          size='large'
          text={tr('FRONTEND.IBAN_REQUEST_OVERVIEW.BUTTON_OKAY_LABEL', 'Okay')}
          onClick={onSuccess}
        />
      </div>
    </div>
  )
}
